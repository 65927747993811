import { Loading3QuartersOutlined } from '@ant-design/icons'
import { Spin } from 'antd'

const antIcon = (
    <Loading3QuartersOutlined
        style={{
            fontSize: 34
        }}
        spin
    />
)
type Props = {
    adisplay?: boolean
}

export default function Spinner({ adisplay }: Props): JSX.Element {
    return (
        <div className={`centerSpinner ${adisplay ? 'innerComponent' : ''}`}>
            <Spin indicator={antIcon} size="large" />
        </div>
    )
}
