export const PROPERTY_MANAGEMENT_APIS = Object.freeze({
    LIST_PROPERTY: `/all-properties`,
    CREATE_PROPERTY: '/properties',
    SINGLE_PROPERTY_UPDATE_DELETE: (id: string) => `/properties/${id}`,
    DUPLICATE_PROPERTY: `/properties/duplicate`,
    PROPERTY_TAGS: '/property-filters',
    ALL_AMENITIES: '/amenities',
    CREATE_PROPERTY_AMENITY: '/properties/amenities',
    GET_PROPERTY_AMENITY: (id: string) => `/property-amenities/${id}`,
    UPDATE_DELETE_PROPERTY_AMENITY: (id: string) => `/properties/amenities/${id}`,
    CREATE_PROPERTY_BLOCKS: `/properties/rooms`,
    GET_PROPERTY_BLOCKS: (id: string) => `/property-rooms/${id}`,
    UPDATE_DELETE_PROPERTY_BLOCKS: (id: string) => `/properties/rooms/${id}`,
    CITIES: '/city',
    // master property
    CREATE_MASTER_PROPERTY: '/master-property',
    GET_MASTER_PROPERTIES: `/master-property`,
    UPDATE_MASTER_PROPERTY: (id: string) => `/master-property/${id}`,
    GET_SUB_PROPERTIES: (id: string) => `/master/sub-properties/${id}`,
    GET_ALL_SUB_PROPERTIES: `/master/sub-properties`,
    GET_PROPERTY_MANAGERS: '/users',
    GET_MASTER_PROPERTY: (id: string) => `/master-property/${id}`,
    GET_COLLECTION_DETAILS: `/ledger`,
    GET_TENANT_DETAILS: (id: string | undefined) => `/tenants/master-property/${id}`,
    GET_TENANT_ID: (id: string | undefined) => `/tenant/single/${id}`
})

export const FILE_UPLOAD_URL = Object.freeze({
    path: `/upload-file`,
    docpath: `/upload-document`
})

export const WEBSITE_CONTENT_APIS = Object.freeze({
    LIST_FAQ_GET_POST: `/faq`,
    FAQ_UPDATE_DELETE: (id: string) => `/faq/${id}`,
    WEBSITE_CONTENT_GET: `/website-content`,
    WEBSITE_CONTENT_UPDATE: `/website-content`,
    ADDRESS_GET_POST: `/address`,
    ADDRESS_UPDATE_DELETE_SINGLEGET: (id: string) => `/address/${id}`,
    TESTIMONIALS_GET_POST: `/testimonials`,
    TESTIMONIALS_UPDATE_DELETE: (id: string) => `/testimonials/${id}`
})

export const USER_MANAGEMENT_APIS = Object.freeze({
    USERS_GET_POST: `/users`,
    USER_ROLES: `/role`,
    UPDATE_USER: (id: string) => `/users/${id}`,
    USER_PROFILE: (cognitoId: string) => `/users/profile/${cognitoId}`,
    RESET_PASSOWRD: `/reset-password`,
    MASTER_PROPERTIES: `/master-property`
})

export const LEADS_APIS = Object.freeze({
    LIST_LEADS: `/leads`,
    LEADS_UPDATE: (id: string) => `/leads/${id}`
})

export const BLOGS_APIS = Object.freeze({
    BLOGS_GET_POST: `/website-content/blog`,
    BLOG_TAGS: `/blog-tags`,
    BLOG_UPDATE_DELETE_SINGLEGET: (id: string) => `/website-content/blog/${id}`
})

export const MASTER_DIRECTORY_APIS = Object.freeze({
    ROLES_POST_GET: `/role`,
    ROLE_UPDATE_DELETE: (id: string) => `/role/${id}`,
    AMENITIES_GET: `/amenities`,
    CREATE_AMENITY: `/amenity`,
    AMENITY_UPDATE_DELETE: (id: string) => `/amenity/${id}`,
    LIST_PROPERTY_TAGS: `/property-tags`,
    CREATE_PROPERTY_TAG: `/property-tag`,
    PROPERTY_TAG_UPDATE_DELETE: (id: string) => `/property-tag/${id}`
})

export const ONBOARDING_APIS = Object.freeze({
    LIST_ONBOARDING: `/booking`,
    SINGLE_ONBOARDING: (id: string) => `/booking/single/${id}`,
    CREATE_ONBOARDING: `/booking/start-onboarding`,
    PAYMENT_LINK_ONBOARDING: `/payment/token/generate-link`,
    UPDATE_ONBOARDING: (id: string) => `/booking/${id}`,
    ONBOARDING_HANDOVER: (id: string) => `/booking/update/${id}`,
    CANCEL_ONBOARDING: (id: string) => `/booking/cancel-onboarding/${id}`,
    AGREEMENT_SENT: (id: string) => `/booking/update/${id}`,
    CREATE_COLLECTION_DETAILS: (id: string) => `/due/add-collection/${id}`,
    UPDATE_APPROVE_REJECT: (id: string) => `/tenant-document/${id}`,
    PENDING_PAYMENTS: (id: string) => `/booking/due/${id}`,
    SEARCH_PROPERTY: `/all-properties`,
    ONBOARDING_ISSUES_GET: `/issue`,
    ONBOARDING_ISSUE_PATCH: (id: string | undefined) => `/issue/${id}`,
    ADD_ONBOARDING_ISSUE: `/issue`,
    ONBOARDING_SUBTYPES_GET: `/issue/subtypes`,
    ONBOARDING_MOVE_IN_DATE_GET_PATCH: (id: string | undefined) => `/booking/move-in-date/${id}`,
    DOCUMENT_URL: `/get-document`,
    TENANT_BY_NO: `/tenants`,
    RENTAL_DOCUMENT_UPLOAD: `/booking/rental-document`,
    RENTAL_DOCUMENT_UPDATE: (id: string | undefined) => `/booking/rental-document/${id}`,
    ONBOARDING_ALL_ACTIVITIES: (id: string) => `/activity/get-all/${id}`,
    COLLECTION_DETAILS: (id: string) => `/ledger/booking/${id}`,
    SEND_AGREEMENT: (id: string) => `/booking/agreement/${id}`
})

export const RAISE_ISSUE_APIS = Object.freeze({
    LIST_RAISE_ISSUES: `/issue`,
    RAISE_ISSUE_UPDATE_SINGLEGET: (id: string) => `/issue/${id}`
})

export const EVICTION_APIS = Object.freeze({
    EXPIRATION_AND_REQUEST: `/eviction`,
    EXPIRATION_PENDING_CHARGES: (id: string) => `/booking/due/${id}`,
    EXPIRATION_REQUEST_DELETE: (id: string) => `/eviction/${id}`,
    EXPIRATION_RENUEW: `/eviction/renew-tenure`,
    EVICTION_DOC_CREATE: `/eviction/renew-document`,
    ALL_TENANTS: `/eviction/get-by-status`,
    TENURE_EXPIRY_DEPART: (id: string) => `/eviction/depart/${id}`,
    EXPIRATION_DEPART_REQUEST: `/depart-requests`,
    RENEWED_TENANTS: `/eviction/renewal`,
    POST_MOVE_OUT_REQUEST: `/eviction`,
    UPDATE_MOVE_OUT_REQUEST: (id: string) => `/eviction/${id}`,
    GET_SINGLE_EVICTION_DETAILS: (id: string) => `/eviction/details/${id}`,
    GET_SINGLE_EVICTION_DETAILS_FROM_TENURE_EXPIRY: (id: string) => `/eviction/booking/${id}`,
    RENEWAL_SINGLE_EVICTION: (id: string) => `/eviction/renewal/${id}`,
    RENEWAL_SIGNER_DOC_UPDATE: (id: string) => `/eviction/renewal/resend-aggreement/${id}`
})
export const EXPENSES_APIS = Object.freeze({
    EXPENSES_POST_GET: `/expenses`,
    EXPENSES_UPDATE_DELETE: (id: string) => `/expenses/${id}`,
    GET_ALL_DUES: `/booking/all-dues`,
    CREATE_DUE: `/due`,
    DUE_UPDATE_DELETE: (id: string) => `/due/${id}`,
    EXPENSE_CATEGORIES_POST_GET: `/misc/expense-category`,
    EXPENSE_CATEGORY_UPDATE_DELETE: (id: string) => `/misc/expense-category/${id}`,
    WHO_PAID: `/expenses/who-paid`,
    EXPENSE_PAID_BY_FILTER: `/expenses/paid-by`,
    KPIS_GET: '/kpis/expense-dues'
})

export const ACCESS_CONTROL_APIS = Object.freeze({
    MODULE_LIST: `/access-control`,
    FUNCTIONS_LIST: `/access-control`,
    ROLE_MODULES: `/access-control/role`,
    DELETE_MODULE_SUBMODULE: (id: string) => `/access-control/${id}`
})

export const COMMON_APIS = Object.freeze({
    SIDEBAR_COUNT: `/sidebar-kpi`
})

export const PERFORMANCE_APIS = Object.freeze({
    ONBOARDING_STATS: (queryParams: string | undefined) => `/performance/onboarding-stats?${queryParams}`,
    ONBOARDING_GRAPH: (queryParams: string | undefined) => `/performance/onboarding-graph?${queryParams}`,
    REQUESTED_TABLE: (queryParams: string | undefined) => `/performance/requested-table?${queryParams}`,
    OCCUPANCY_STATUS: (queryParams: string | undefined) => `/performance/occupancy-status?${queryParams}`,
    ONBOARDING_TYPES_STATS: (queryParams: string | undefined) => `/performance/occupancy-type-status?${queryParams}`,
    GET_ALL_ISSUES: (queryParams: string | undefined) => `/performance/get-all-issues?${queryParams}`,
    ISSUE_TYPE_GRAPH: (queryParams: string | undefined) => `/performance/issue-type-graph?${queryParams}`,
    MOVE_SUMMARY: (queryParams: string | undefined) => `/performance/move-summary?${queryParams}`,
    MOVE_IN_TABLE: (queryParams: string | undefined) => `/performance/move-in-table?${queryParams}`,
    MOVE_OUT_TABLE: (queryParams: string | undefined) => `/performance/move-out-table?${queryParams}`,
    GET_SUMMARY: (queryParams: string | undefined) => `/performance/get-summary?${queryParams}`,
    MASTER_PROPERTY: (queryParams: string | undefined) => `/performance/master-property?${queryParams}`,
    PROPERTY: (queryParams: string | undefined) => `/performance/property?${queryParams}`,
    PERFORMANCE_REPORT: `/performance/admin-finance`
})
