import { UseMutationResult, UseQueryResult, keepPreviousData, useMutation, useQuery } from '@tanstack/react-query'
import { PaginationTypes } from 'types/common.types'
import {
    MASTER_PROPERTIES_GET,
    ROLEWISE_MODULES,
    USERS_MANAGEMENT_GET,
    USERS_MANAGEMENT_PATCH,
    USERS_MANAGEMENT_POST,
    USERS_RESET_PASSWORD,
    USER_MANAGEMENT_ROLES_GET,
    USER_PROFILE_GET
} from './api-helper'
import { GetRolesRoot, UsersRoot, resetPasswordType, userSingleData, userType } from 'types/user-management.types'

export const USE_GET_LIST_USERS = (
    pagination: PaginationTypes,
    search: string,
    status: string
): UseQueryResult<UsersRoot, Error> =>
    useQuery({
        queryKey: ['USE_GET_LIST_USERS', pagination.current, pagination.pageSize, search, status],
        queryFn: () => USERS_MANAGEMENT_GET(pagination.current, pagination.pageSize, search, status),
        retry: 0,
        placeholderData: keepPreviousData
    })

export const USE_USER_PROFILE = (cognitoId: string): UseQueryResult<userSingleData, Error> =>
    useQuery({
        queryKey: ['USE_USER_PROFILE', cognitoId],
        queryFn: () => USER_PROFILE_GET(cognitoId),
        placeholderData: keepPreviousData,
        enabled: !!cognitoId
    })

export const USE_GET_ROLES_USERS = (): UseQueryResult<GetRolesRoot, Error> =>
    useQuery({
        queryKey: ['USE_GET_ROLES_USERS'],
        queryFn: () => USER_MANAGEMENT_ROLES_GET(),
        placeholderData: keepPreviousData
    })

export const USE_CREATE_USERS = (): UseMutationResult<any, Error, any, unknown> =>
    useMutation({ mutationFn: (values: userType) => USERS_MANAGEMENT_POST(values) })

export const USE_RESET_PASSWORD = (): UseMutationResult<any, Error, any, unknown> =>
    useMutation({ mutationFn: (values: resetPasswordType) => USERS_RESET_PASSWORD(values) })

export const USE_UPDATE_USERS = (): UseMutationResult<any, Error, any, unknown> =>
    useMutation({
        mutationFn: (values: userType) => USERS_MANAGEMENT_PATCH(values)
    })

export const USE_GET_MASTER_PROPERTIES = (
    pagination: PaginationTypes,
    screen?: string,
    userId?: string,
    propertyManagerId?: string,
    maintainer_id?: string
): UseQueryResult<any, Error> =>
    useQuery({
        queryKey: [
            'USE_GET_MASTER_PROPERTIES',
            pagination.current,
            pagination.pageSize,
            screen,
            userId,
            propertyManagerId,
            maintainer_id
        ],
        queryFn: () =>
            MASTER_PROPERTIES_GET(
                pagination?.current,
                pagination?.pageSize,
                screen,
                userId,
                propertyManagerId,
                maintainer_id
            ),
        retry: 0,
        placeholderData: keepPreviousData
    })

//optimistic update single property data
export const optimisticUserMangaement = (
    queryClient: any,
    type: string,
    res: any,
    pagination?: PaginationTypes
): void => {
    const updateQueryData = (updater: (oldData: any) => any): void => {
        queryClient.setQueryData(
            ['USE_GET_LIST_USERS', pagination?.current, pagination?.pageSize, '', ''],
            (oldData: any) => updater(oldData)
        )
    }

    switch (type) {
        case 'user_edit':
            updateQueryData((old: UsersRoot) => ({
                ...old,
                data: {
                    ...old?.data,
                    data: old?.data?.data?.map((user: userType) => {
                        if (user.id === res?.data?.id) {
                            return res.data
                        }
                        return user
                    })
                }
            }))
            break
        case 'user_switch_status':
            updateQueryData((old: UsersRoot) => ({
                ...old,
                data: {
                    ...old?.data,
                    data: old?.data?.data?.map((user: userType) => {
                        if (user.id === res?.id) {
                            return { ...res, disabled: !res.disabled }
                        }
                        return user
                    })
                }
            }))
            break
        case 'user_add':
            queryClient.refetchQueries({
                queryKey: ['USE_GET_LIST_USERS', pagination?.current, pagination?.pageSize, '', '']
            })
            break
        default:
            queryClient.refetchQueries({
                queryKey: ['USE_GET_LIST_USERS', pagination?.current, pagination?.pageSize, '', '']
            })
            break
    }
}

export const USE_ROLEWISE_MODULES = (ids: string[]): UseQueryResult<any, Error> =>
    useQuery({
        queryKey: ['USE_ROLEWISE_MODULES', ids],
        queryFn: () => ROLEWISE_MODULES(ids),
        retry: 0,
        enabled: !!ids && ids.length > 0,
        placeholderData: keepPreviousData
    })

export function transformAccessGetData(data: any, type: string): any {
    if (type === 'from_edit_user') {
        const access = data?.user_submodules?.map((module: any) => {
            const functions = module?.sub_modules?.map((subModule: any) => subModule.id)
            const functionAccessMap: { [key: string]: string[] } = {}

            module.sub_modules.forEach((subModule: any) => {
                functionAccessMap[subModule.id] = subModule.access_types.map((accessType: any) => accessType.id)
            })

            return {
                module: module.id,
                function: functions,
                ...functionAccessMap
            }
        })

        return { access }
    } else {
        const access = data?.data?.map((module: any) => {
            const functions = module?.sub_modules?.map((subModule: any) => subModule.id)
            const functionAccessMap: { [key: string]: string[] } = {}

            module.sub_modules.forEach((subModule: any) => {
                functionAccessMap[subModule.id] = subModule.access_types.map((accessType: any) => accessType.id)
            })

            return {
                module: module.id,
                function: functions,
                ...functionAccessMap
            }
        })
        return { access }
    }
}

export function filterAndMergeAccessTypes(data1: any, data2: any): any {
    // Convert data2 into a Map for quick lookup
    const data2Map = new Map()

    data2.forEach((module: any) => {
        const subModuleMap = new Map()
        module?.sub_modules?.forEach((sub: any) => subModuleMap.set(sub?.id, sub?.access_types))
        data2Map.set(module?.id, subModuleMap)
    })

    // Filter and update data1 based on data2
    return data1
        .filter((module: any) => data2Map.has(module?.id)) // Only keep modules that exist in data2
        .map((module: any) => ({
            ...module,
            sub_modules: module?.sub_modules
                .filter((sub: any) => data2Map.get(module?.id).has(sub?.id)) // Only keep sub-modules that exist in data2
                .map((sub: any) => ({
                    ...sub,
                    access_types: data2Map.get(module?.id).get(sub?.id) || []
                }))
        }))
        .filter((module: any) => module?.sub_modules?.length > 0) // Remove modules with no sub-modules left
}
