/* eslint-disable no-unused-vars */
import { USE_USER_PROFILE } from 'components/user-management/api-helper/services'
import { ReactNode, createContext, useContext, useEffect, useState } from 'react'
import { userSingleData } from 'types/user-management.types'
import useCurrentAuthenticatedUser from 'hooks/use-current-authenticated-user'
import { MainContext } from 'App'
import { renderRoutes } from 'utils/components/routes'
import useWindowDimensions from 'hooks/use-window-dimensions'
import {
    SortingKeys,
    filterSendingKeys,
    filterOnboardingTableKeys,
    filterIssuesTableKeys,
    filterRequestedTableKeys
} from 'types/property-management.types'
import { PaginationTypes, SidebarCountRoot } from 'types/common.types'
import { ExpenseFilterType } from 'types/expenses.types'
import { USE_GET_SIDEBAR_COUNTS } from '../sidebar/api-helper/services'

type Props = { children: ReactNode }
export const CommonStateContext = createContext<{
    propertyPagination: PaginationTypes
    setPropertyPagination: (pagination: PaginationTypes) => void
    masterPropertyPagination: PaginationTypes
    setMasterPropertyPagination: (pagination: PaginationTypes) => void
    masterPropertySearch: string
    setMasterPropertySearch: (masterPropertySearch: string) => void
    logOutOpen: boolean
    setLogOutOpen: (logOutOpen: boolean) => void
    collapsed: boolean
    setCollapsed: (collapsed: boolean) => void
    siderActive: string
    setSiderActive: (siderActive: string) => void
    previewOpen: boolean
    setPreviewOpen: (previewOpen: boolean) => void
    previewImage: string
    setPreviewImage: (previewImage: string) => void
    previewTitle: string
    setPreviewTitle: (previewTitle: string) => void
    userProfile: userSingleData | undefined
    profileLoading: boolean
    filterOptions: filterSendingKeys
    setFilterOptions: (filterOptions: filterSendingKeys) => void
    onboardingDetailsFilterOptions: filterOnboardingTableKeys
    issueDetailsFilterOptions: filterIssuesTableKeys
    setOnboardingDetailsFilterOptions: (filterOptions: filterOnboardingTableKeys) => void
    setIssueDetailsFilterOptions: (filterOptions: filterIssuesTableKeys) => void
    requestedDetailsFilterOptions: filterRequestedTableKeys
    setRequestedDetailsFilterOptions: (filterOptions: filterRequestedTableKeys) => void
    activeFilter: boolean
    setActiveFilter: (logOutOpen: boolean) => void
    sortingOptions: SortingKeys
    setSortingOptions: (sortingOptions: SortingKeys) => void
    sortingMoveInOptions: SortingKeys
    setSortingMoveInOptions: (sortingOptions: SortingKeys) => void
    sortingMoveOutOptions: SortingKeys
    setSortingMoveOutOptions: (sortingOptions: SortingKeys) => void
    sortingRequestOptions: SortingKeys
    setSortingRequestOptions: (sortingOptions: SortingKeys) => void
    sortingOnboardingOptions: SortingKeys
    setSortingOnboardingOptions: (sortingOptions: SortingKeys) => void
    onboardSortingOptions: SortingKeys
    setOnBoardSortingOptions: (sortingOptions: SortingKeys) => void
    onboardFilterOptions: Record<string, any>
    setOnBoardFilterOptions: (onboardFilterOptions: Record<string, any>) => void
    masterSortingOptions: SortingKeys
    setMasterSortingOptions: (sortingOptions: SortingKeys) => void
    sortingIssuesOptions: SortingKeys
    setSortingIssuesOptions: (sortingOptions: SortingKeys) => void
    expenseTabs: string | number
    setExpenseTabs: (expenseTabs: string | number) => void
    expenseSearch: string
    setExpenseSearch: (expenseSearch: string) => void
    onboardingDetailsSearch: string
    setOnboardingDetailsSearch: (onboardingDetailsSearch: string) => void
    issuesTableSearch: string
    setIssuesTableSearch: (onboardingDetailsSearch: string) => void
    expFilterOptions: ExpenseFilterType
    setExpFilterOptions: (expFilterOptions: ExpenseFilterType) => void
    expActiveFilter: boolean
    setExpActiveFilter: (expActiveFilter: boolean) => void
    expensePagination: PaginationTypes
    setExpensePagination: (pagination: PaginationTypes) => void
    onboardingDetailsPagination: PaginationTypes
    setOnboardingDetailsPagination: (pagination: PaginationTypes) => void
    issuesTablePagination: PaginationTypes
    setIssuesTablePagination: (pagination: PaginationTypes) => void
    requestedPagination: PaginationTypes
    setRequestedPagination: (pagination: PaginationTypes) => void
    requestedSearch: string
    setRequestedSearch: (dueSearch: string) => void
    dueSearch: string
    setDueSearch: (dueSearch: string) => void
    duesPagination: PaginationTypes
    setDuesPagination: (pagination: PaginationTypes) => void
    onboardStatus: string[]
    setOnboardStatus: (onboardStatus: string[]) => void
    sortingExpOptions: SortingKeys
    setSortingExpOptions: (sortingExpOptions: SortingKeys) => void
    masterPropertyTabs: string
    setMasterPropertyTabs: (masterPropertyTabs: string) => void
    subPropertySorting: SortingKeys
    setSubPropertySorting: (subPropertySorting: SortingKeys) => void
    userPagination: PaginationTypes
    setUserPagination: (pagination: PaginationTypes) => void
    userSearch: string
    setUserSearch: (userSearch: string) => void
    userStatus: string
    setUserStatus: (userStatus: string) => void
    rolesPagination: PaginationTypes
    setRolesPagination: (pagination: PaginationTypes) => void
    roleSearch: string
    setRoleSearch: (roleSearch: string) => void
    evictionTenureTabs: string
    setEvictionTenureTabs: (evictionTenureTabs: string) => void
    expirationRequestTabs: string | number
    setExpirationRequestTabs: (expirationRequestTabs: string | number) => void
    setExpirationRequestPagination: (pagination: PaginationTypes) => void
    expirationRequestPagination: PaginationTypes
    renewedPagination: PaginationTypes
    setRenewedPagination: (pagination: PaginationTypes) => void
    renewedSearch: string
    setRenewedSearch: (renewedSearch: string) => void
    renewedSorting: SortingKeys
    setRenewedSorting: (renewedSorting: SortingKeys) => void
    renewedTenantFilter: string
    setRenewedTenantFilter: (renewedTenantFilter: string) => void
    departedPagination: PaginationTypes
    setDepartedPagination: (pagination: PaginationTypes) => void
    departedSearch: string
    setDepartedSearch: (departedSearch: string) => void
    departedSorting: SortingKeys
    setDepartedSorting: (departedSorting: SortingKeys) => void
    cancelledPagination: PaginationTypes
    setCancelledPagination: (pagination: PaginationTypes) => void
    cancelledSearch: string
    setCancelledSearch: (cancelledSearch: string) => void
    cancelledSorting: SortingKeys
    setCancelledSorting: (cancelledSorting: SortingKeys) => void
    declinedPagination: PaginationTypes
    setDeclinedPagination: (pagination: PaginationTypes) => void
    declinedSearch: string
    setDeclinedSearch: (cancelledSearch: string) => void
    declinedSorting: SortingKeys
    setDeclinedSorting: (declinedSorting: SortingKeys) => void
    issuesPagination: PaginationTypes
    setIssuesPagination: (pagination: PaginationTypes) => void
    openIssues: string | number
    setOpenIssues: (openIssues: string | number) => void
    issuesFilterStatus: string[]
    setIssuesFilterStatus: (issuesFilterStatus: string[]) => void
    masterPExpTabs: string | number
    setMasterPExpTabs: (masterPExpTabs: string | number) => void
    masterPExpSearch: string
    setMasterPExpSearch: (masterPExpSearch: string) => void
    masterPExpPagination: PaginationTypes
    masterPDuesSearch: string
    setMasterPDuesSearch: (masterPDuesSearch: string) => void
    setMasterPExpPagination: (pagination: PaginationTypes) => void
    masterPDuesPagination: PaginationTypes
    setMasterPDuesPagination: (pagination: PaginationTypes) => void
    masterPExpFilterOptions: ExpenseFilterType
    setMasterPExpFilterOptions: (masterPExpFilterOptions: ExpenseFilterType) => void
    masterPExpActiveFilter: boolean
    setMasterPExpActiveFilter: (masterPExpActiveFilter: boolean) => void
    masterPSortingExpOptions: SortingKeys
    setMasterPSortingExpOptions: (masterPSortingExpOptions: SortingKeys) => void
    masterPExpCollectionPagination: PaginationTypes
    setMasterPExpCollectionPagination: (pagination: PaginationTypes) => void
    masterPExpCollectionSearch: string
    setMasterPExpCollectionSearch: (masterPExpCollectionSearch: string) => void
    masterPTenantPagination: PaginationTypes
    setMasterPTenantPagination: (pagination: PaginationTypes) => void
    masterPTenantSearch: string
    setMasterPTenantSearch: (masterPTenantSearch: string) => void
    masterPTenantBookingPagination: PaginationTypes
    setMasterPTenantBookingPagination: (pagination: PaginationTypes) => void
    masterPSubPPagination: PaginationTypes
    setMasterPSubPPagination: (pagination: PaginationTypes) => void
    masterPSubPSearch: string
    setMasterPSubPSearch: (masterPSubPSearch: string) => void
    expReqFilter: string
    setExpReqFilter: (expReqFilter: string) => void
    departFilter: string
    setDepartFilter: (departFilter: string) => void
    expReqDepartFilter: string
    setExpReqDepartFilter: (expReqDepartFilter: string) => void
    sidebarCounts: SidebarCountRoot | undefined
    sidebarLoading: boolean
    issuesSortingOptions: SortingKeys
    setIssuesSortingOptions: (sortingOptions: SortingKeys) => void
}>({
    propertyPagination: { current: 1, pageSize: 10, total: 0 },
    setPropertyPagination: (pagination: PaginationTypes) => pagination,
    masterPropertyPagination: { current: 1, pageSize: 10, total: 0 },
    setMasterPropertyPagination: (pagination: PaginationTypes) => pagination,
    masterPropertySearch: '',
    setMasterPropertySearch: (masterPropertySearch: string) => masterPropertySearch,
    logOutOpen: false,
    setLogOutOpen: (logOutOpen: boolean) => logOutOpen,
    collapsed: false,
    setCollapsed: (collapsed: boolean) => collapsed,
    siderActive: '',
    setSiderActive: (siderActive: string) => siderActive,
    previewOpen: false,
    setPreviewOpen: (previewOpen: boolean) => previewOpen,
    previewImage: '',
    setPreviewImage: (previewImage: string) => previewImage,
    previewTitle: '',
    setPreviewTitle: (previewTitle: string) => previewTitle,
    userProfile: undefined,
    profileLoading: false,
    filterOptions: {
        property_type: undefined,
        city: undefined,
        status: undefined,
        active: undefined,
        bedrooms: undefined,
        bathrooms: undefined,
        price: [],
        priceFrom: undefined,
        priceTo: undefined
    },
    setFilterOptions: (filterOptions: filterSendingKeys) => filterOptions,
    onboardingDetailsFilterOptions: {
        master_property_id: undefined,
        property_id: undefined,
        rent_start: undefined,
        rent_end: undefined
    },
    issueDetailsFilterOptions: {
        property_id: undefined,
        raised_by: undefined,
        type: undefined,
        status: undefined
    },
    setOnboardingDetailsFilterOptions: (filterOptions: filterOnboardingTableKeys) => filterOptions,
    setIssueDetailsFilterOptions: (filterOptions: filterIssuesTableKeys) => filterOptions,
    requestedDetailsFilterOptions: {
        property_id: undefined,
        master_property_id: undefined,
        date: undefined
    },
    setRequestedDetailsFilterOptions: (filterOptions: filterRequestedTableKeys) => filterOptions,
    activeFilter: false,
    setActiveFilter: (logOutOpen: boolean) => logOutOpen,
    sortingOptions: {
        sort: undefined
    },
    setSortingOptions: (sortingOptions: SortingKeys) => sortingOptions,
    sortingMoveInOptions: {
        sort: undefined
    },
    setSortingMoveInOptions: (sortingOptions: SortingKeys) => sortingOptions,
    sortingMoveOutOptions: {
        sort: undefined
    },
    setSortingMoveOutOptions: (sortingOptions: SortingKeys) => sortingOptions,
    onboardSortingOptions: {
        sort: undefined
    },
    setOnBoardSortingOptions: (sortingOptions: SortingKeys) => sortingOptions,
    sortingRequestOptions: {
        sort: undefined
    },
    setSortingRequestOptions: (sortingOptions: SortingKeys) => sortingOptions,
    sortingOnboardingOptions: {
        sort: undefined
    },
    setSortingOnboardingOptions: (sortingOptions: SortingKeys) => sortingOptions,
    onboardFilterOptions: {
        city: null
    },
    setOnBoardFilterOptions: (onboardFilterOptions: Record<string, any>) => onboardFilterOptions,
    masterSortingOptions: {
        sort: undefined
    },
    setMasterSortingOptions: (sortingOptions: SortingKeys) => sortingOptions,
    sortingIssuesOptions: {
        sort: undefined
    },
    setSortingIssuesOptions: (sortingOptions: SortingKeys) => sortingOptions,
    expenseTabs: 'expense',
    setExpenseTabs: (expenseTabs: string | number) => expenseTabs,
    expenseSearch: '',
    setExpenseSearch: (expenseSearch: string) => expenseSearch,
    onboardingDetailsSearch: '',
    setOnboardingDetailsSearch: (onboardingDetailsSearch: string) => onboardingDetailsSearch,
    issuesTableSearch: '',
    setIssuesTableSearch: (onboardingDetailsSearch: string) => onboardingDetailsSearch,
    dueSearch: '',
    setDueSearch: (dueSearch: string) => dueSearch,
    requestedPagination: { current: 1, pageSize: 10, total: 0 },
    setRequestedPagination: (pagination: PaginationTypes) => pagination,
    requestedSearch: '',
    setRequestedSearch: (requestedSearch: string) => requestedSearch,
    expFilterOptions: {
        property: undefined,
        category: undefined,
        date: undefined,
        paid_by: undefined
    },
    setExpFilterOptions: (expFilterOptions: ExpenseFilterType) => expFilterOptions,
    expActiveFilter: false,
    setExpActiveFilter: (expActiveFilter: boolean) => expActiveFilter,
    expensePagination: { current: 1, pageSize: 10, total: 0 },
    setExpensePagination: (pagination: PaginationTypes) => pagination,
    onboardingDetailsPagination: { current: 1, pageSize: 10, total: 0 },
    setOnboardingDetailsPagination: (pagination: PaginationTypes) => pagination,
    issuesTablePagination: { current: 1, pageSize: 10, total: 0 },
    setIssuesTablePagination: (pagination: PaginationTypes) => pagination,
    duesPagination: { current: 1, pageSize: 10, total: 0 },
    setDuesPagination: (pagination: PaginationTypes) => pagination,
    onboardStatus: [],
    setOnboardStatus: (onboardStatus: string[]) => onboardStatus,
    sortingExpOptions: {
        sort: undefined
    },
    setSortingExpOptions: (sortingExpOptions: SortingKeys) => sortingExpOptions,
    masterPropertyTabs: 'master_property_details',
    setMasterPropertyTabs: (masterPropertyTabs: string) => masterPropertyTabs,
    subPropertySorting: { sort: undefined },
    setSubPropertySorting: (subPropertySorting: SortingKeys) => subPropertySorting,
    userPagination: { current: 1, pageSize: 10, total: 0 },
    setUserPagination: (pagination: PaginationTypes) => pagination,
    userSearch: '',
    setUserSearch: (userSearch: string) => userSearch,
    userStatus: '',
    setUserStatus: (userStatus: string) => userStatus,
    rolesPagination: { current: 1, pageSize: 10, total: 0 },
    setRolesPagination: (pagination: PaginationTypes) => pagination,
    roleSearch: '',
    setRoleSearch: (roleSearch: string) => roleSearch,
    evictionTenureTabs: 'eviction_tenure',
    setEvictionTenureTabs: (evictionTenureTabs: string) => evictionTenureTabs,
    expirationRequestTabs: 'tenure_expiry',
    setExpirationRequestTabs: (expirationRequestTabs: string | number) => expirationRequestTabs,
    setExpirationRequestPagination: (pagination: PaginationTypes) => pagination,
    expirationRequestPagination: { current: 1, pageSize: 10, total: 0 },
    renewedPagination: { current: 1, pageSize: 10, total: 0 },
    setRenewedPagination: (pagination: PaginationTypes) => pagination,
    renewedSearch: '',
    setRenewedSearch: (renewedSearch: string) => renewedSearch,
    renewedSorting: { sort: undefined },
    setRenewedSorting: (renewedSorting: SortingKeys) => renewedSorting,
    renewedTenantFilter: '',
    setRenewedTenantFilter: (renewedTenantFilter: string) => renewedTenantFilter,
    departedPagination: { current: 1, pageSize: 10, total: 0 },
    setDepartedPagination: (pagination: PaginationTypes) => pagination,
    departedSearch: '',
    setDepartedSearch: (departedSearch: string) => departedSearch,
    departedSorting: { sort: undefined },
    setDepartedSorting: (departedSorting: SortingKeys) => departedSorting,
    cancelledPagination: { current: 1, pageSize: 10, total: 0 },
    setCancelledPagination: (pagination: PaginationTypes) => pagination,
    cancelledSearch: '',
    setCancelledSearch: (cancelledSearch: string) => cancelledSearch,
    cancelledSorting: { sort: undefined },
    setCancelledSorting: (cancelledSorting: SortingKeys) => cancelledSorting,
    declinedPagination: { current: 1, pageSize: 10, total: 0 },
    setDeclinedPagination: (pagination: PaginationTypes) => pagination,
    declinedSearch: '',
    setDeclinedSearch: (cancelledSearch: string) => cancelledSearch,
    declinedSorting: { sort: undefined },
    setDeclinedSorting: (declinedSorting: SortingKeys) => declinedSorting,
    issuesPagination: { current: 1, pageSize: 10, total: 0 },
    setIssuesPagination: (pagination: PaginationTypes) => pagination,
    openIssues: 'open',
    setOpenIssues: (openIssues: string | number) => openIssues,
    issuesFilterStatus: [],
    setIssuesFilterStatus: (issuesFilterStatus: string[]) => issuesFilterStatus,
    masterPExpTabs: 'expense',
    setMasterPExpTabs: (masterPExpTabs: string | number) => masterPExpTabs,
    masterPExpSearch: '',
    setMasterPExpSearch: (masterPExpSearch: string) => masterPExpSearch,
    masterPDuesSearch: '',
    setMasterPDuesSearch: (masterPDuesSearch: string) => masterPDuesSearch,
    masterPExpPagination: { current: 1, pageSize: 10, total: 0 },
    setMasterPExpPagination: (pagination: PaginationTypes) => pagination,
    masterPDuesPagination: { current: 1, pageSize: 10, total: 0 },
    setMasterPDuesPagination: (pagination: PaginationTypes) => pagination,
    masterPExpFilterOptions: {
        property: undefined,
        category: undefined,
        date: undefined,
        paid_by: undefined
    },
    setMasterPExpFilterOptions: (masterPExpFilterOptions: ExpenseFilterType) => masterPExpFilterOptions,
    masterPExpActiveFilter: false,
    setMasterPExpActiveFilter: (masterPExpActiveFilter: boolean) => masterPExpActiveFilter,
    masterPSortingExpOptions: {
        sort: undefined
    },
    setMasterPSortingExpOptions: (masterPSortingExpOptions: SortingKeys) => masterPSortingExpOptions,
    masterPExpCollectionPagination: { current: 1, pageSize: 10, total: 0 },
    setMasterPExpCollectionPagination: (pagination: PaginationTypes) => pagination,
    masterPExpCollectionSearch: '',
    setMasterPExpCollectionSearch: (masterPExpCollectionSearch: string) => masterPExpCollectionSearch,

    masterPTenantPagination: { current: 1, pageSize: 10, total: 0 },
    setMasterPTenantPagination: (pagination: PaginationTypes) => pagination,
    masterPTenantSearch: '',
    setMasterPTenantSearch: (masterPTenantSearch: string) => masterPTenantSearch,
    masterPTenantBookingPagination: { current: 1, pageSize: 10, total: 0 },
    setMasterPTenantBookingPagination: (pagination: PaginationTypes) => pagination,
    masterPSubPPagination: { current: 1, pageSize: 10, total: 0 },
    setMasterPSubPPagination: (pagination: PaginationTypes) => pagination,
    masterPSubPSearch: '',
    setMasterPSubPSearch: (masterPSubPSearch: string) => masterPSubPSearch,
    expReqFilter: 'tenure_expiry',
    setExpReqFilter: (expReqFilter: string) => expReqFilter,
    departFilter: '',
    setDepartFilter: (departFilter: string) => departFilter,
    expReqDepartFilter: '',
    setExpReqDepartFilter: (expReqDepartFilter: string) => expReqDepartFilter,
    sidebarCounts: undefined,
    sidebarLoading: false,
    issuesSortingOptions: {
        sort: undefined
    },
    setIssuesSortingOptions: (sortingOptions: SortingKeys) => sortingOptions
})

export default function CommonStates({ children }: Props): JSX.Element {
    const { width } = useWindowDimensions()
    const { user } = useCurrentAuthenticatedUser()
    const {
        data: userProfile,
        isFetching: profileLoading,
        isError
    } = USE_USER_PROFILE(user?.username ? user?.username : '')
    const { data: sidebarCounts, isFetching: sidebarLoading } = USE_GET_SIDEBAR_COUNTS()

    const [logOutOpen, setLogOutOpen] = useState<boolean>(false)
    const [collapsed, setCollapsed] = useState<boolean>(width < 1440 ? true : false)
    const [siderActive, setSiderActive] = useState<string>('')
    const [previewOpen, setPreviewOpen] = useState<boolean>(false)
    const [previewImage, setPreviewImage] = useState<string>('')
    const [previewTitle, setPreviewTitle] = useState<string>('')
    const { setRolesArray, setRoutes, setProfile } = useContext(MainContext)
    const [filterOptions, setFilterOptions] = useState<filterSendingKeys>({
        property_type: undefined,
        city: undefined,
        status: undefined,
        active: ['active'],
        bedrooms: undefined,
        bathrooms: undefined,
        priceFrom: undefined,
        priceTo: undefined
    })
    const [onboardingDetailsFilterOptions, setOnboardingDetailsFilterOptions] = useState<filterOnboardingTableKeys>({
        master_property_id: undefined,
        property_id: undefined,
        rent_start: undefined,
        rent_end: undefined
    })
    const [issueDetailsFilterOptions, setIssueDetailsFilterOptions] = useState<filterIssuesTableKeys>({
        property_id: undefined,
        raised_by: undefined,
        type: undefined,
        status: undefined
    })
    const [requestedDetailsFilterOptions, setRequestedDetailsFilterOptions] = useState<filterRequestedTableKeys>({
        property_id: undefined,
        master_property_id: undefined,
        date: undefined
    })
    const [sortingOptions, setSortingOptions] = useState<SortingKeys>({
        sort: null
    })
    const [sortingMoveInOptions, setSortingMoveInOptions] = useState<SortingKeys>({
        sort: null
    })
    const [sortingMoveOutOptions, setSortingMoveOutOptions] = useState<SortingKeys>({
        sort: null
    })
    const [sortingRequestOptions, setSortingRequestOptions] = useState<SortingKeys>({
        sort: null
    })
    const [sortingOnboardingOptions, setSortingOnboardingOptions] = useState<SortingKeys>({
        sort: null
    })
    const [onboardSortingOptions, setOnBoardSortingOptions] = useState<SortingKeys>({
        sort: null
    })
    const [onboardFilterOptions, setOnBoardFilterOptions] = useState<Record<string, any>>({
        city: null
    })
    const [masterSortingOptions, setMasterSortingOptions] = useState<SortingKeys>({
        sort: null
    })
    const [sortingIssuesOptions, setSortingIssuesOptions] = useState<SortingKeys>({
        sort: null
    })
    const [propertyPagination, setPropertyPagination] = useState<PaginationTypes>({
        current: 1,
        pageSize: 10,
        total: 0
    })
    const [masterPropertyPagination, setMasterPropertyPagination] = useState<PaginationTypes>({
        current: 1,
        pageSize: 10,
        total: 0
    })
    const [masterPropertySearch, setMasterPropertySearch] = useState<string>('')
    const [activeFilter, setActiveFilter] = useState<boolean>(false)
    const [expenseTabs, setExpenseTabs] = useState<string | number>('expense')
    const [expenseSearch, setExpenseSearch] = useState<string>('')
    const [onboardingDetailsSearch, setOnboardingDetailsSearch] = useState<string>('')
    const [issuesTableSearch, setIssuesTableSearch] = useState<string>('')
    const [dueSearch, setDueSearch] = useState<string>('')
    const [expFilterOptions, setExpFilterOptions] = useState<any>({})
    const [expActiveFilter, setExpActiveFilter] = useState<boolean>(false)
    const [expensePagination, setExpensePagination] = useState<PaginationTypes>({
        current: 1,
        pageSize: 10,
        total: 0
    })
    const [onboardingDetailsPagination, setOnboardingDetailsPagination] = useState<PaginationTypes>({
        current: 1,
        pageSize: 10,
        total: 0
    })
    const [issuesTablePagination, setIssuesTablePagination] = useState<PaginationTypes>({
        current: 1,
        pageSize: 10,
        total: 0
    })
    const [requestedPagination, setRequestedPagination] = useState<PaginationTypes>({
        current: 1,
        pageSize: 10,
        total: 0
    })
    const [requestedSearch, setRequestedSearch] = useState<string>('')
    const [duesPagination, setDuesPagination] = useState<PaginationTypes>({
        current: 1,
        pageSize: 10,
        total: 0
    })
    const [onboardStatus, setOnboardStatus] = useState<string[]>([])
    const [sortingExpOptions, setSortingExpOptions] = useState<SortingKeys>({
        sort: null
    })
    const [masterPropertyTabs, setMasterPropertyTabs] = useState<string>('master_property_details')
    const [subPropertySorting, setSubPropertySorting] = useState<SortingKeys>({
        sort: null
    })
    const [userPagination, setUserPagination] = useState<PaginationTypes>({ current: 1, pageSize: 10, total: 0 })
    const [userSearch, setUserSearch] = useState<string>('')
    const [userStatus, setUserStatus] = useState<string>('')
    const [rolesPagination, setRolesPagination] = useState<PaginationTypes>({ current: 1, pageSize: 10, total: 0 })
    const [roleSearch, setRoleSearch] = useState<string>('')
    const [evictionTenureTabs, setEvictionTenureTabs] = useState<string>('tenure_details')
    const [expirationRequestTabs, setExpirationRequestTabs] = useState<string | number>('tenure_expiry')
    const [expirationRequestPagination, setExpirationRequestPagination] = useState<PaginationTypes>({
        current: 1,
        pageSize: 10,
        total: 0
    })

    const [renewedPagination, setRenewedPagination] = useState<PaginationTypes>({ current: 1, pageSize: 10, total: 0 })
    const [renewedSearch, setRenewedSearch] = useState<string>('')
    const [renewedSorting, setRenewedSorting] = useState<SortingKeys>({
        sort: null
    })
    const [renewedTenantFilter, setRenewedTenantFilter] = useState<string>('')

    const [departedPagination, setDepartedPagination] = useState<PaginationTypes>({
        current: 1,
        pageSize: 10,
        total: 0
    })
    const [departedSearch, setDepartedSearch] = useState<string>('')
    const [departedSorting, setDepartedSorting] = useState<SortingKeys>({
        sort: null
    })
    const [cancelledPagination, setCancelledPagination] = useState<PaginationTypes>({
        current: 1,
        pageSize: 10,
        total: 0
    })
    const [cancelledSearch, setCancelledSearch] = useState<string>('')
    const [cancelledSorting, setCancelledSorting] = useState<SortingKeys>({
        sort: null
    })
    const [declinedPagination, setDeclinedPagination] = useState<PaginationTypes>({
        current: 1,
        pageSize: 10,
        total: 0
    })
    const [declinedSearch, setDeclinedSearch] = useState<string>('')
    const [declinedSorting, setDeclinedSorting] = useState<SortingKeys>({
        sort: null
    })

    // issues states ===>
    const [issuesPagination, setIssuesPagination] = useState<PaginationTypes>({ current: 1, pageSize: 10, total: 0 })
    const [openIssues, setOpenIssues] = useState<string | number>('open')
    const [issuesFilterStatus, setIssuesFilterStatus] = useState<string[]>([])
    const [issuesSortingOptions, setIssuesSortingOptions] = useState<SortingKeys>({
        sort: null
    })
    // master property states ===>
    const [masterPExpTabs, setMasterPExpTabs] = useState<string | number>('expense')
    const [masterPExpSearch, setMasterPExpSearch] = useState<string>('')
    const [masterPExpPagination, setMasterPExpPagination] = useState<PaginationTypes>({
        current: 1,
        pageSize: 10,
        total: 0
    })
    const [masterPDuesSearch, setMasterPDuesSearch] = useState<string>('')
    const [masterPDuesPagination, setMasterPDuesPagination] = useState<PaginationTypes>({
        current: 1,
        pageSize: 10,
        total: 0
    })
    const [masterPExpFilterOptions, setMasterPExpFilterOptions] = useState<any>({})
    const [masterPExpActiveFilter, setMasterPExpActiveFilter] = useState<boolean>(false)
    const [masterPSortingExpOptions, setMasterPSortingExpOptions] = useState<SortingKeys>({
        sort: null
    })
    const [masterPExpCollectionPagination, setMasterPExpCollectionPagination] = useState<PaginationTypes>({
        current: 1,
        pageSize: 10,
        total: 0
    })
    const [masterPExpCollectionSearch, setMasterPExpCollectionSearch] = useState<string>('')
    const [masterPTenantPagination, setMasterPTenantPagination] = useState<PaginationTypes>({
        current: 1,
        pageSize: 10,
        total: 0
    })
    const [masterPTenantSearch, setMasterPTenantSearch] = useState<string>('')
    const [masterPTenantBookingPagination, setMasterPTenantBookingPagination] = useState<PaginationTypes>({
        current: 1,
        pageSize: 5,
        total: 0
    })
    const [masterPSubPPagination, setMasterPSubPPagination] = useState<PaginationTypes>({
        current: 1,
        pageSize: 10,
        total: 0
    })
    const [masterPSubPSearch, setMasterPSubPSearch] = useState<string>('')
    const [expReqFilter, setExpReqFilter] = useState<string>('tenure_expiry')
    const [departFilter, setDepartFilter] = useState<string>('')
    const [expReqDepartFilter, setExpReqDepartFilter] = useState<string>('')

    useEffect(() => {
        if (userProfile) {
            if (userProfile?.data?.result?.roles && userProfile?.data?.result?.roles?.length > 0) {
                const accessedRoutes = userProfile?.data?.result?.user_modules ?? []
                const routes = renderRoutes(
                    accessedRoutes,
                    userProfile?.data?.result?.roles?.map((role) => role?.role) ?? []
                )
                setRolesArray(accessedRoutes)
                setRoutes(routes)
                setProfile(userProfile?.data?.result)
            } else if (userProfile?.data === null) {
                setProfile(userProfile?.data)
            } else if (userProfile?.data?.result?.id) {
                setProfile(userProfile.data?.result)
            }
        }
    }, [userProfile, setRolesArray, setRoutes, setProfile])

    useEffect(() => {
        if (isError) {
            setRolesArray([])
            setProfile({ noAccess: true, id: undefined, is_property_manager: false, is_maintainer: false })
            setRoutes([])
        }
    }, [isError])

    return (
        <CommonStateContext.Provider
            value={{
                logOutOpen,
                setLogOutOpen,
                collapsed,
                setCollapsed,
                siderActive,
                setSiderActive,
                previewOpen,
                setPreviewOpen,
                previewImage,
                setPreviewImage,
                previewTitle,
                setPreviewTitle,
                userProfile,
                profileLoading,
                filterOptions,
                setFilterOptions,
                propertyPagination,
                setPropertyPagination,
                masterPropertyPagination,
                setMasterPropertyPagination,
                masterPropertySearch,
                setMasterPropertySearch,
                activeFilter,
                setActiveFilter,
                setSortingOptions,
                sortingOptions,
                onboardSortingOptions,
                setOnBoardSortingOptions,
                masterSortingOptions,
                setMasterSortingOptions,
                sortingIssuesOptions,
                setSortingIssuesOptions,
                expenseTabs,
                setExpenseTabs,
                onboardingDetailsSearch,
                setOnboardingDetailsSearch,
                expenseSearch,
                setExpenseSearch,
                dueSearch,
                setDueSearch,
                expFilterOptions,
                setExpFilterOptions,
                expActiveFilter,
                setExpActiveFilter,
                setExpensePagination,
                expensePagination,
                duesPagination,
                setDuesPagination,
                setOnboardStatus,
                onboardStatus,
                sortingExpOptions,
                setSortingExpOptions,
                setOnBoardFilterOptions,
                onboardFilterOptions,
                masterPropertyTabs,
                setMasterPropertyTabs,
                setSubPropertySorting,
                subPropertySorting,
                userPagination,
                setUserPagination,
                userSearch,
                setUserSearch,
                userStatus,
                setUserStatus,
                rolesPagination,
                setRolesPagination,
                roleSearch,
                setRoleSearch,
                evictionTenureTabs,
                setEvictionTenureTabs,
                setExpirationRequestTabs,
                expirationRequestTabs,
                expirationRequestPagination,
                setExpirationRequestPagination,
                renewedPagination,
                setRenewedPagination,
                renewedSearch,
                setRenewedSearch,
                renewedSorting,
                setRenewedSorting,
                renewedTenantFilter,
                setRenewedTenantFilter,
                departedPagination,
                setDepartedPagination,
                departedSearch,
                setDepartedSearch,
                departedSorting,
                setDepartedSorting,
                cancelledPagination,
                setCancelledPagination,
                cancelledSearch,
                setCancelledSearch,
                cancelledSorting,
                setCancelledSorting,
                declinedPagination,
                setDeclinedPagination,
                declinedSearch,
                setDeclinedSearch,
                declinedSorting,
                setDeclinedSorting,
                issuesPagination,
                setIssuesPagination,
                openIssues,
                setOpenIssues,
                issuesFilterStatus,
                setIssuesFilterStatus,
                expReqFilter,
                setExpReqFilter,
                departFilter,
                setDepartFilter,
                expReqDepartFilter,
                setExpReqDepartFilter,
                masterPExpTabs,
                setMasterPExpTabs,
                masterPExpSearch,
                setMasterPExpSearch,
                masterPExpPagination,
                setMasterPExpPagination,
                masterPDuesSearch,
                setMasterPDuesSearch,
                masterPDuesPagination,
                setMasterPDuesPagination,
                masterPExpFilterOptions,
                setMasterPExpFilterOptions,
                masterPExpActiveFilter,
                setMasterPExpActiveFilter,
                masterPSortingExpOptions,
                setMasterPSortingExpOptions,
                masterPExpCollectionPagination,
                setMasterPExpCollectionPagination,
                masterPExpCollectionSearch,
                setMasterPExpCollectionSearch,
                masterPTenantPagination,
                setMasterPTenantPagination,
                masterPTenantSearch,
                setMasterPTenantSearch,
                masterPTenantBookingPagination,
                setMasterPTenantBookingPagination,
                masterPSubPPagination,
                setMasterPSubPPagination,
                masterPSubPSearch,
                setMasterPSubPSearch,
                sidebarCounts,
                sidebarLoading,
                issuesSortingOptions,
                setIssuesSortingOptions,
                onboardingDetailsPagination,
                setOnboardingDetailsPagination,
                issuesTablePagination,
                setIssuesTablePagination,
                issuesTableSearch,
                setIssuesTableSearch,
                requestedPagination,
                setRequestedPagination,
                requestedSearch,
                setRequestedSearch,
                onboardingDetailsFilterOptions,
                setOnboardingDetailsFilterOptions,
                setIssueDetailsFilterOptions,
                issueDetailsFilterOptions,
                setRequestedDetailsFilterOptions,
                requestedDetailsFilterOptions,
                sortingRequestOptions,
                setSortingRequestOptions,
                sortingOnboardingOptions,
                setSortingOnboardingOptions,
                sortingMoveInOptions,
                setSortingMoveInOptions,
                sortingMoveOutOptions,
                setSortingMoveOutOptions
            }}>
            {children}
        </CommonStateContext.Provider>
    )
}
