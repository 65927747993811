import { GetProp, message, SelectProps, UploadFile, UploadProps } from 'antd'
import dayjs from 'dayjs'
import {
    PropertiesListResult,
    Property,
    PropertyTagImgType,
    SinglePropertyData,
    filterOptionType
} from 'types/property-management.types'
import bachelorsImage from 'assests/images/property_images/bachelors.png'
import coLivingImage from 'assests/images/property_images/co-living.png'
import depositImage from 'assests/images/property_images/deposit.png'
import familyImage from 'assests/images/property_images/family.png'
import furnishedImage from 'assests/images/property_images/furnished.png'
import mealsImage from 'assests/images/property_images/meals.png'
import menImage from 'assests/images/property_images/men.png'
import s_furnishedImage from 'assests/images/property_images/semi-furnished.png'
import womenImage from 'assests/images/property_images/women.png'
import { cityRoot } from 'types/common.types'
import { ReactNode } from 'react'
import { RangePickerProps } from 'antd/es/date-picker'
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    DollarCircleOutlined,
    FileDoneOutlined,
    FileTextOutlined,
    KeyOutlined,
    QuestionCircleOutlined,
    FileExclamationOutlined,
    SolutionOutlined,
    CalendarOutlined,
    CarryOutOutlined,
    FilePdfOutlined,
    CommentOutlined,
    SendOutlined,
    FileImageOutlined,
    WarningOutlined
} from '@ant-design/icons'
import { Maintenance } from 'types/raise-issue-types'
import { RoleSubmodule } from 'types/roles.types'
import { Signer } from 'types/onboarding.types'

export const initialCenter = {
    lat: 12.9716,
    lng: 77.5946
}

export const imageFormatRegex = /\.(jpg|jpeg|png|gif|webp|avif|jfif|pjpeg|pjp|bmp|ico|cur|tif|tiff)$/i

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]
export const getBase64 = (file: FileType): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (): void => resolve(reader.result as string)
        reader.onerror = (error): void => reject(error)
    })
export const handlePreview = async (
    file: UploadFile,
    setPreviewImage: (previewImage: string) => void,
    setPreviewOpen: (previewOpen: boolean) => void,
    setPreviewTitle: (previewTitle: string) => void
): Promise<void> => {
    if (Object.keys(file)?.length === 2 && file?.url) {
        setPreviewImage(file.url)
        setPreviewOpen(true)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        setPreviewTitle(`${file.uid}|BASE64`)
    } else {
        if (!file.fileName && !file.preview) {
            file.preview = await getBase64(file.originFileObj as FileType)
        }
        setPreviewImage(file.fileName || (file.preview as string))
        setPreviewOpen(true)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1))
    }
}

export function openBase64PDF(base64Data: any): void {
    try {
        // Remove data URL prefix if it exists
        const base64Content = base64Data.includes(',') ? base64Data.split(',')[1] : base64Data

        // Decode Base64 to binary
        const binaryData = atob(base64Content)
        const len = binaryData.length
        const buffer = new ArrayBuffer(len)
        const view = new Uint8Array(buffer)

        // Convert binary string to array buffer
        for (let i = 0; i < len; i++) {
            view[i] = binaryData.charCodeAt(i)
        }

        // Create Blob object
        const blob = new Blob([view], { type: 'application/pdf' })

        // Generate Object URL and open in a new window/tab
        const blobURL = URL.createObjectURL(blob)
        window.open(blobURL, '_blank')
    } catch (error) {
        console.error('Error opening PDF:', error)
    }
}

export function imageViewer(key: string, size: string): string {
    const previewComImgUrl = process.env.REACT_APP_COMPRESSED_IMAGE_URL
    const previewImgUrl = process.env.REACT_APP_IMAGE_URL
    if (size == 'raw') {
        return previewImgUrl + key
    } else {
        const keyPath = key
        const parts = keyPath.split('/')
        const lastIndex = parts.length - 1
        const newKey = parts.slice(0, lastIndex).join('/') + `/${size}_` + parts[lastIndex]
        return previewComImgUrl + newKey
    }
}

export const propertyFilterOptions = (addressData: cityRoot | undefined): filterOptionType[] => {
    return [
        {
            label: 'Property type',
            value: 'property_type',

            children: ['pg', 'rooms', 'house']?.map((item) => ({
                label: firstCapitalLetter(item),
                value: item
            }))
        },
        {
            label: 'Bedrooms',
            value: 'bedrooms',

            children: ['1', '2', '3', '4', '5', '5+']?.map((item) => ({ label: item, value: item }))
        },
        {
            label: 'Bathrooms',
            value: 'bathrooms',

            children: ['1', '2', '3', '4', '5', '5+']?.map((item) => ({ label: item, value: item }))
        },
        {
            label: 'City',
            value: 'city',
            children: addressData?.data?.map((item) => ({
                label: item?.name,
                value: item?.name
            }))
        },
        {
            label: 'Status',
            value: 'status',

            children: ['available', 'vacating soon', 'occupied']?.map((item) => ({
                label: firstCapitalLetter(item),
                value: item
            }))
        },
        {
            label: 'Live',
            value: 'active',
            children: ['active', 'inactive']?.map((item) => ({
                label: firstCapitalLetter(item),
                value: item
            }))
        }
    ]
}

export const amountConverter = (amount: string | number): string => {
    const value =
        typeof amount === 'string'
            ? parseFloat(amount).toLocaleString('en-IN', {
                  style: 'decimal',
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0
              })
            : amount.toLocaleString('en-IN', {
                  style: 'decimal',
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0
              })
    return value
}

export const amountConverterDecimal = (amount: number | string): string => {
    const value =
        typeof amount === 'string'
            ? parseFloat(amount).toLocaleString('en-IN', {
                  style: 'decimal',
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 1
              })
            : amount.toLocaleString('en-IN', {
                  style: 'decimal',
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 1
              })
    return value
}
export const propertyTagsWithImage = (label: string): PropertyTagImgType | null => {
    const tags = [
        {
            label: 'Only for women',
            keyword: 'Only for women',
            image: womenImage,
            color: '#ED1CBE'
        },
        {
            label: 'Only for men',
            keyword: 'Only for men',
            image: menImage,
            color: '#1C58ED'
        },
        {
            label: 'Co-live',
            keyword: 'Co-live',
            image: coLivingImage,
            color: '#7E30A5'
        },
        {
            label: 'For bachelors',
            keyword: 'For bachelors',
            image: bachelorsImage,
            color: '#30A53C'
        },
        {
            label: 'Security deposit refund',
            keyword: 'Security deposit refund',
            image: depositImage,
            color: '#FD7B00'
        },
        {
            label: 'For families',
            keyword: 'For families',
            image: familyImage,
            color: '#E52424'
        },
        {
            label: 'Fully furnished',
            keyword: 'Fully furnished',
            image: furnishedImage,
            color: '#A61485'
        },
        {
            label: 'Meals included',
            keyword: 'Meals included',
            image: mealsImage,
            color: '#0DC4D0'
        },
        {
            label: 'Semi furnished',
            keyword: 'Semi furnished',
            image: s_furnishedImage,
            color: '#4C46C8'
        }
    ]
    const foundTag = tags.find((item) => item.label === label)
    return foundTag ? foundTag : null
}

// Extracting RGB values from hex color code
export const hexToRgb = (hex: string): string => {
    const bigint = parseInt(hex.replace('#', ''), 16)
    const r = (bigint >> 16) & 255
    const g = (bigint >> 8) & 255
    const b = bigint & 255
    return `${r}, ${g}, ${b}`
}

export const FAQCategories = [
    { value: 'renting', label: 'Renting' },
    { value: 'during_my_stay', label: 'During my stay' },
    { value: 'terms_of_stay', label: 'Terms of stay' },
    { value: 'payments', label: 'Payments' }
]

export const commonFilterOption = (input: string, option?: { label: ReactNode; value: string; title: string }): any => {
    return (option?.title ?? '').toLowerCase().includes(input.toLowerCase())
}

// export const FeatureTagMatch =
//     /-(bathroom|bedroom|kitchen|balcony|terrace|garden|backyard|frontyard|patio|hall|living-area|parking|gym|dining-room|swiming-pool|playground)-(\d+)/
// export const featureTags = [
//     { label: 'Bedroom', color: '#FF5733' },
//     { label: 'Bathroom', color: '#33A1FF' },
//     { label: 'Kitchen', color: '#FFBD33' },
//     { label: 'Balcony', color: '#33FF57' },
//     { label: 'Terrace', color: '#FF33A8' },
//     { label: 'Garden', color: '#A833FF' },
//     { label: 'Backyard', color: '#33FFF1' },
//     { label: 'Frontyard', color: '#FFD133' },
//     { label: 'Patio', color: '#FF3380' },
//     { label: 'Hall', color: '#33FF99' },
//     { label: 'Living area', color: '#336EFF' },
//     { label: 'Parking', color: '#F43553' },
//     { label: 'Gym', color: '#8D33FF' },
//     { label: 'Dining room', color: '#FF8F33' },
//     { label: 'Swimming pool', color: '#33CFFF' },
//     { label: 'Playground', color: '#FFC433' }
// ]

export const FeatureTagMatch =
    /-(bathroom|bedroom|kitchen|balcony|terrace|garden|backyard|frontyard|patio|hall|living-area|parking|gym|dining-room|swimming-pool|playground|covered-parking|uncovered-parking|corridor|utility-room|storage-room|staircase|reception-lobby|security-room|building-exterior|elevator-lift|common-areas|clubhouse|security-features|flooring|windows-doors|lighting-fixtures|furniture|air-conditioning-ventilation|storage|signage-building-name|smart-home-features|walking-trails|public-amenities|backup-power-supply|water-supply-sewage-system|cctv|guest-room)-(\d+)/

export const featureTags = [
    { label: 'Bedroom', color: '#FF5733' },
    { label: 'Bathroom', color: '#33A1FF' },
    { label: 'Kitchen', color: '#FFBD33' },
    { label: 'Balcony', color: '#33FF57' },
    { label: 'Terrace', color: '#FF33A8' },
    { label: 'Garden', color: '#A833FF' },
    { label: 'Backyard', color: '#33FFF1' },
    { label: 'Frontyard', color: '#FFD133' },
    { label: 'Patio', color: '#FF3380' },
    { label: 'Hall', color: '#33FF99' },
    { label: 'Living Area', color: '#336EFF' },
    { label: 'Parking', color: '#F43553' },
    { label: 'Gym', color: '#8D33FF' },
    { label: 'Dining Room', color: '#FF8F33' },
    { label: 'Swimming Pool', color: '#33CFFF' },
    { label: 'Playground', color: '#FFC433' },
    { label: 'Covered Parking', color: '#44A1A0' },
    { label: 'Uncovered Parking', color: '#A0A144' },
    { label: 'Corridor', color: '#D833FF' },
    { label: 'Utility Room', color: '#FF33D1' },
    { label: 'Storage Room', color: '#A1FF33' },
    { label: 'Staircase', color: '#33FFB8' },
    { label: 'Reception / Lobby', color: '#FF7D33' },
    { label: 'Security Room', color: '#FF3370' },
    { label: 'Building Exterior', color: '#3370FF' },
    { label: 'Elevator / Lift', color: '#9933FF' },
    { label: 'Common Areas', color: '#33FFA8' },
    { label: 'Clubhouse', color: '#FFA833' },
    { label: 'Security Features', color: '#338AFF' },
    { label: 'Flooring', color: '#8AFF33' },
    { label: 'Windows & Doors', color: '#FF5733' },
    { label: 'Lighting Fixtures', color: '#FFA133' },
    { label: 'Furniture', color: '#337AFF' },
    { label: 'Air Conditioning & Ventilation', color: '#FF33C1' },
    { label: 'Storage', color: '#99FF33' },
    { label: 'Signage / Building Name', color: '#FFAA33' },
    { label: 'Smart Home Features', color: '#33FF99' },
    { label: 'Walking Trails', color: '#33C1FF' },
    { label: 'Public Amenities', color: '#FF3357' },
    { label: 'Backup Power Supply', color: '#33FFAA' },
    { label: 'Water Supply & Sewage System', color: '#AA33FF' },
    { label: 'CCTV', color: '#33A8FF' },
    { label: 'Guest Room', color: '#FF33AA' }
]

export const amenitiesTreeData = [
    {
        title: 'Bedroom',
        value: 'Bedroom',
        selectable: false,

        children: [
            {
                title: 'Master Bedroom',
                value: 'Master Bedroom'
            },
            {
                title: 'En-suite Bedroom',
                value: 'En-suite Bedroom'
            },
            {
                title: 'Family Bedroom',
                value: 'Family Bedroom'
            },
            {
                title: 'Guest Bedroom',
                value: 'Guest Bedroom'
            },
            {
                title: 'Powder Room',
                value: 'Powder Room'
            },
            {
                title: 'Wet Room',
                value: 'Wet Room'
            },
            {
                title: 'Half Bedroom',
                value: 'Half Bedroom'
            },
            {
                title: 'Full Bedroom',
                value: 'Full Bedroom'
            },
            {
                title: 'Jack and Jill Bedroom',
                value: 'Jack and Jill Bedroom'
            },
            {
                title: 'Walk-in Shower Bedroom',
                value: 'Walk-in Shower Bedroom'
            },
            {
                title: 'Shared Bedroom',
                value: 'Shared Bedroom'
            },
            {
                title: 'Disabled Access Bedroom',
                value: 'Disabled Access Bedroom'
            },
            {
                title: 'Spa Bedroom',
                value: 'Spa Bedroom'
            },
            {
                title: 'Luxury Bedroom',
                value: 'Luxury Bedroom'
            }
        ]
    },
    {
        title: 'Bathroom',
        value: 'Bathroom',
        selectable: false,
        children: [
            {
                title: 'Master Bathroom',
                value: 'Master Bathroom'
            },
            {
                title: 'Shared Bathroom',
                value: 'Shared Bathroom'
            },
            {
                title: 'Disabled Access Bathroom',
                value: 'Disabled Access Bathroom'
            },
            {
                title: 'Spa Bathroom',
                value: 'Spa Bathroom'
            },
            {
                title: 'Luxury Bathroom',
                value: 'Luxury Bathroom'
            },
            {
                title: 'Attached Bathroom',
                value: 'Attached Bathroom'
            },
            {
                title: 'En-suite Bathroom',
                value: 'En-suite Bathroom'
            },
            {
                title: 'Family Bathroom',
                value: 'Family Bathroom'
            },
            {
                title: 'Guest Bathroom',
                value: 'Guest Bathroom'
            },
            {
                title: 'Powder Room',
                value: 'Powder Room'
            },
            {
                title: 'Wet Room',
                value: 'Wet Room'
            },
            {
                title: 'Half Bathroom',
                value: 'Half Bathroom'
            },
            {
                title: 'Full Bathroom',
                value: 'Full Bathroom'
            },
            {
                title: 'Walk-in Shower Bathroom',
                value: 'Walk-in Shower Bathroom'
            }
        ]
    },
    {
        title: 'Kitchen',
        value: 'Kitchen',
        selectable: false,
        children: [
            {
                title: 'Modular Kitchen',
                value: 'Modular Kitchen'
            },
            {
                title: 'Open Style Kitchen',
                value: 'Open Style Kitchen'
            },
            {
                title: 'Closed Kitchen',
                value: 'Closed Kitchen'
            },
            {
                title: 'L-Shaped Kitchen',
                value: 'L-Shaped Kitchen'
            },
            {
                title: 'U-Shaped Kitchen',
                value: 'U-Shaped Kitchen'
            },
            {
                title: 'Island Kitchen',
                value: 'Island Kitchen'
            },
            {
                title: 'Galley Kitchen',
                value: 'Galley Kitchen'
            },
            {
                title: 'Peninsula Kitchen',
                value: 'Peninsula Kitchen'
            },
            {
                title: 'Breakfast Nook Kitchen',
                value: 'Breakfast Nook Kitchen'
            },
            {
                title: 'Single-Wall Kitchen',
                value: 'Single-Wall Kitchen'
            }
        ]
    },
    {
        title: 'Outdoor spaces',
        value: 'Outdoor spaces',
        selectable: false,
        children: [
            {
                title: 'Balcony',
                value: 'Balcony'
            },
            {
                title: 'Terrace',
                value: 'Terrace'
            },
            {
                title: 'Garden',
                value: 'Garden'
            },
            {
                title: 'Patio / Deck',
                value: 'Patio / Deck'
            },
            {
                title: 'Backyard / Front Yard',
                value: 'Backyard / Front Yard'
            }
        ]
    },
    {
        title: 'Living area / Hall',
        value: 'Living area / Hall',
        selectable: false,
        children: [
            {
                title: 'Family Room',
                value: 'Family Room'
            },
            {
                title: 'Living Hall',
                value: 'Living Hall'
            },
            {
                title: 'Sunroom',
                value: 'Sunroom'
            },
            {
                title: 'Entertainment Room',
                value: 'Entertainment Room'
            },
            {
                title: 'Media Room',
                value: 'Media Room'
            },
            {
                title: 'Reception Area',
                value: 'Reception Area'
            },
            {
                title: 'Sitting Room',
                value: 'Sitting Room'
            },
            {
                title: 'Open Plan Living Area',
                value: 'Open Plan Living Area'
            }
        ]
    },
    {
        title: 'Parking space',
        value: 'Parking space',
        selectable: false,
        children: [
            {
                title: 'Gated Parking',
                value: 'Gated Parking'
            },
            {
                title: 'Private Parking',
                value: 'Private Parking'
            },
            {
                title: 'Reserved Parking',
                value: 'Reserved Parking'
            },
            {
                title: 'Multi-car Parking',
                value: 'Multi-car Parking'
            },
            {
                title: 'Shared Parking',
                value: 'Shared Parking'
            },
            {
                title: 'Driveway Parking',
                value: 'Driveway Parking'
            },
            {
                title: 'Garage Parking',
                value: 'Garage Parking'
            },
            {
                title: 'Carport Parking',
                value: 'Carport parking'
            },
            {
                title: 'On-street Parking',
                value: 'On-street Parking'
            },
            {
                title: 'Basement Parking',
                value: 'Basement Parking'
            },
            {
                title: 'Covered Parking',
                value: 'Covered Parking'
            },
            {
                title: 'Open Parking',
                value: 'Open Parking'
            },
            {
                title: 'Tandem Parking',
                value: 'Tandem Parking'
            },
            {
                title: 'Portico Parking',
                value: 'Portico Parking'
            }
        ]
    },
    {
        title: 'Community space',
        value: 'Community space',
        selectable: false,
        children: [
            {
                title: 'Clubhouse',
                value: 'Clubhouse'
            },
            {
                title: 'Gym / Fitness Center',
                value: 'Gym / Fitness Center'
            },
            {
                title: 'Swimming Pool',
                value: 'Swimming Pool'
            },
            {
                title: 'Walking Trails',
                value: 'Walking Trails'
            },
            {
                title: 'Playground',
                value: 'Playground'
            }
        ]
    },
    {
        title: 'Other',
        value: 'Other',
        selectable: false,
        children: [
            {
                title: 'Dining Room',
                value: 'Dining Room'
            }
        ]
    }
]

export const getneratePropertyImgFileName = (
    property: SinglePropertyData | undefined,
    feature_type: string | undefined,
    count: number | undefined
): string => {
    let fileName = ''
    switch (property?.property_type) {
        case 'pg':
            fileName += ``
            break
        default:
            fileName += `${property?.bedrooms || ''}${property?.house_type}-`
            break
    }
    // Handle furnish type
    switch (property?.furnish_type) {
        case 'semifurnished':
            fileName += 'semi-furnished-'
            break
        case 'furnished':
            fileName += 'fully-furnished-'
            break
        default:
            break // No filename prefix for unfurnished
    }
    // Handle property type
    switch (property?.property_type) {
        case 'rooms':
            fileName += 'flat-'
            break
        default:
            fileName += `${property?.property_type}-`
            break
    }
    // Add common parts
    if (property?.property_type === 'pg') {
        fileName += `for-${property?.pg_for ? property?.pg_for : ''}-in-${property?.area}-`
    } else {
        fileName += `for-rent-in-${property?.area}-`
    }

    if (property?.landmark) {
        fileName += `near-${property?.landmark}-`
    }
    fileName += `at-${property?.name}-${feature_type}-${count}`

    const cleanedFilename = fileName
        .toLowerCase()
        .replace(/,/g, '')
        .replace(/ /g, '-')
        .replace(/-+/g, '-')
        .replace(/^-|-$/g, '')

    return cleanedFilename
}

export const disabledTodayBeforeDates: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().startOf('day')
}

export const disableAfterToday: RangePickerProps['disabledDate'] = (current) => {
    return current && current > dayjs().endOf('day')
}
// All activities status =>

export type ActivityStatusType =
    | 'token_paid'
    | 'deposit_paid'
    | 'service_fee_paid'
    | 'rent_paid'
    | 'payment_paid'
    | 'created_onboarding'
    | 'onboarded'
    | 'kyc_pending'
    | 'kyc_uploaded'
    | 'kyc_declined'
    | 'kyc_approved'
    | 'agreement_uploaded'
    | 'agreement_sent'
    | 'raised_issue'
    | 'closed_issue'
    | 'change_mid_request'
    | 'approved_mid_request'
    | 'declined_mid_request'
    | 'key_handover'
    | 'cancelled_onboarding'

export const createActivitiesStatus = (status: string, user: string, owner: string): any => {
    const activityStatus = {
        token_paid: {
            status: 'Token paid',
            message: `<b>${user}</b> has paid the token amount.`,
            icon: DollarCircleOutlined
        },
        deposit_paid: {
            status: 'Deposit paid',
            message: `<b>${user}</b> has paid the security deposit.`,
            icon: DollarCircleOutlined
        },
        service_fee_paid: {
            status: 'Service fee paid',
            message: `<b>${user}</b> has paid the service fee.`,
            icon: DollarCircleOutlined
        },
        rent_paid: { status: 'Rent paid', message: `<b>${user}</b> has paid the rent.`, icon: DollarCircleOutlined },
        payment_paid: {
            status: 'Payment completed',
            message: `<b>${user}</b> has completed all required payments.`,
            icon: DollarCircleOutlined
        },
        created_onboarding: {
            status: 'Onboarding created',
            message: `Onboarding process has been initiated for <b>${user}</b>.`,
            icon: SolutionOutlined
        },
        onboarded: {
            status: 'Onboarding completed',
            message: `<b>${user}</b> has successfully completed the onboarding process.`,
            icon: CheckCircleOutlined
        },
        kyc_pending: {
            status: 'KYC documents pending',
            message: `Waiting for <b>${user}</b> to submit KYC documents.`,
            icon: FileImageOutlined
        },
        kyc_uploaded: {
            status: 'KYC documents uploaded',
            message: `<b>${user}</b> has uploaded the KYC documents.`,
            icon: FileTextOutlined
        },
        kyc_declined: {
            status: 'KYC documents declined',
            message: `<b>${user}</b>'s KYC documents have been declined.`,
            icon: FileExclamationOutlined
        },
        kyc_approved: {
            status: 'KYC documents approved',
            message: `<b>${user}</b>'s KYC documents have been approved.`,
            icon: FileDoneOutlined
        },
        agreement_uploaded: {
            status: 'Rental agreement uploaded',
            message: `<b>${owner}</b> has uploaded the rental agreement.`,
            icon: FilePdfOutlined
        },
        agreement_pending: {
            status: 'Rental agreement pending',
            message: `<b>${user}</b> needs to upload the rental agreement.`,
            icon: FilePdfOutlined
        },
        agreement_sent: {
            status: 'Rental agreement sent',
            message: `Rental agreement has been sent to <b>${user}</b>.`,
            icon: SendOutlined
        },
        raised_issue: {
            status: 'Issue raised',
            message: `<b>${user}</b> has raised an issue.`,
            icon: QuestionCircleOutlined
        },
        closed_issue: {
            status: 'Issue closed',
            message: `The issue raised by <b>${user}</b> has been resolved.`,
            icon: CommentOutlined
        },
        change_mid_request: {
            status: 'Move-in date change requested',
            message: `<b>${user}</b> has requested a change in the move-in date.`,
            icon: CalendarOutlined
        },
        approved_mid_request: {
            status: 'Move-in date change approved',
            message: `<b>${owner}</b> has approved <b>${user}</b>'s request to change the move-in date.`,
            icon: CarryOutOutlined
        },
        declined_mid_request: {
            status: 'Move-in date change declined',
            message: `<b>${owner}</b> has declined <b>${user}</b>'s request to change the move-in date.`,
            icon: CarryOutOutlined
        },
        key_handover: {
            status: 'Key handed over',
            message: `<b>${owner}</b> has handed over the keys to <b>${user}</b>.`,
            icon: KeyOutlined
        },
        cancelled_onboarding: {
            status: 'Onboarding cancelled',
            message: `The onboarding process for <b>${user}</b> has been cancelled.`,
            icon: CloseCircleOutlined
        }
    }

    if (status in activityStatus) {
        return activityStatus[status as keyof typeof activityStatus]
    } else {
        // Fallback response for unknown statuses
        return {
            status: status?.replace(/_/g, ' '),
            message: ``,
            icon: WarningOutlined // You can choose an appropriate icon for warnings
        }
    }
}

export function formatRupees(number: number | undefined): string {
    if (number === undefined || number === null) {
        return ''
    } else {
        const cleanedNumber = String(number).replace(/[^\d.]/g, '')
        // Convert the input to a float for processing
        const num = parseFloat(cleanedNumber)
        // Check if the number has decimal points
        if (isNaN(num)) return ''
        const hasDecimal = num % 1 !== 0
        // Use the appropriate converter function
        if (hasDecimal) {
            return amountConverterDecimal(num) // Call function for decimal formatting
        } else {
            return amountConverter(num) // Call function for integer formatting
        }
    }
}

export async function copyToClipboard(text: string): Promise<void> {
    try {
        await navigator.clipboard.writeText(text)
        message.success('Link copied to clipboard')
    } catch (err) {
        console.error('Failed to copy: ', err)
    }
}
export type issueTypes =
    | 'maintenance'
    | 'food'
    | 'security'
    | 'facilities'
    | 'roommate'
    | 'payments'
    | 'rental_agreement'
    | 'others'

export const issuesConstant = {
    maintenance: {
        title: 'Maintenance',
        color: '#FFECFF',
        textColor: '#CF4ACE',
        subType: {
            furniture: {
                title: 'Furniture',
                key: 'furniture'
            },
            washroom: {
                title: 'Washroom',
                key: 'washroom'
            },
            appliances: {
                title: 'Appliances',
                key: 'appliances'
            },
            plumbing: {
                title: 'Plumbing',
                key: 'plumbing'
            },
            lighting: {
                title: 'Lighting',
                key: 'lighting'
            },
            pests: {
                title: 'Pests',
                key: 'pests'
            },
            structural_damage: {
                title: 'Structural Damage',
                key: 'structural_damage'
            },
            electricals: {
                title: 'Electricals',
                key: 'electricals'
            },
            cleaning_sweeping: {
                title: 'Cleaning & Sweeping',
                key: 'cleaning_sweeping'
            },
            neighbour_issue: {
                title: 'Neighbour Issue',
                key: 'neighbour_issue'
            },
            others: {
                title: 'Others',
                key: 'others'
            }
        }
    },
    food: {
        title: 'Food',
        color: '#FCF5E8',
        textColor: '#FFA608',
        subType: {
            quality: {
                title: 'Quality',
                key: 'quality'
            },
            quantity: {
                title: 'Quantity',
                key: 'quantity'
            },
            hygiene: {
                title: 'Hygiene',
                key: 'hygiene'
            },
            dietary_needs: {
                title: 'Dietary Needs',
                key: 'dietary_needs'
            },
            serving: {
                title: 'Serving',
                key: 'serving'
            },
            self_cooking: {
                title: 'Self Cooking',
                key: 'self_cooking'
            },
            others: {
                title: 'Others',
                key: 'others'
            }
        }
    },
    security: {
        title: 'Security',
        color: '#FFF2EF',
        textColor: '#FF866D',
        subType: {
            cctv: {
                title: 'CCTV',
                key: 'cctv'
            },
            theft: {
                title: 'Theft',
                key: 'theft'
            },
            staff: {
                title: 'Staff',
                key: 'staff'
            },
            others: {
                title: 'Others',
                key: 'others'
            }
        }
    },
    facilities: {
        title: 'Facilities',
        color: '#E9FEF8',
        textColor: '#00A371',
        subType: {
            lift: {
                title: 'Lift',
                key: 'lift'
            },
            internet: {
                title: 'Internet',
                key: 'internet'
            },
            laundary: {
                title: 'Laundary',
                key: 'laundary'
            },
            electricity: {
                title: 'Electricity',
                key: 'electricity'
            },
            noise: {
                title: 'Noise',
                key: 'noise'
            },
            water: {
                title: 'Water',
                key: 'water'
            },
            parking: {
                title: 'Parking',
                key: 'parking'
            },
            gym: {
                title: 'Gym',
                key: 'gym'
            },
            cleanliness: {
                title: 'Cleanliness',
                key: 'cleanliness'
            },
            shared_spaces: {
                title: 'Shared Spaces',
                key: 'shared_spaces'
            },
            access_control: {
                title: 'Access Control',
                key: 'access_control'
            },
            pool: {
                title: 'Pool',
                key: 'pool'
            },
            trash_disposal: {
                title: 'Trash Disposal',
                key: 'trash_disposal'
            },
            utility_bill: {
                title: 'Utility Bill',
                key: 'utility_bill'
            },
            borewell: {
                title: 'Borewell',
                key: 'borewell'
            },
            generator: {
                title: 'Generator',
                key: 'generator'
            },
            others: {
                title: 'Others',
                key: 'others'
            }
        }
    },
    roommate: {
        title: 'Roommate',
        color: '#ECFFFF',
        textColor: '#009EA2',
        subType: {
            social_incompability: {
                title: 'Social Incompability',
                key: 'social_incompability'
            },
            misbehaviour: {
                title: 'Misbehaviour',
                key: 'misbehaviour'
            },
            noise_levels: {
                title: 'Noise Levels',
                key: 'noise_levels'
            },
            sharing_utilities: {
                title: 'Sharing Utilities',
                key: 'sharing_utilities'
            },
            cleanliness: {
                title: 'cleanliness',
                key: 'cleanliness'
            },
            others: {
                title: 'Others',
                key: 'others'
            }
        }
    },
    payments: {
        title: 'Payments',
        color: '#EBF9FF',
        textColor: '#0096D1',
        subType: {
            monthly_rent: {
                title: 'Monthly Rent',
                key: 'monthly_rent'
            },
            security_deposit: {
                title: 'Security Deposit',
                key: 'security_deposit'
            },
            electricity_bill: {
                title: 'Electricity Bill',
                key: 'electricity_bill'
            },
            gas_bill: {
                title: 'Gas Bill',
                key: 'gas_bill'
            },
            maintenance_bill: {
                title: 'Maintenance Bill',
                key: 'maintenance_bill'
            },
            late_fee: {
                title: 'Late Fee',
                key: 'late_fee'
            },
            payment_methods: {
                title: 'Payment Methods',
                key: 'payment_methods'
            },
            penalty: {
                title: 'Penalty',
                key: 'penalty'
            },
            service_fee: {
                title: 'Service Fee',
                key: 'service_fee'
            },
            police_verification: {
                title: 'Police Verification',
                key: 'police_verification'
            },
            mess: {
                title: 'Mess',
                key: 'mess'
            },
            others: {
                title: 'Others',
                key: 'others'
            }
        }
    },
    rental_agreement: {
        title: 'Rental Agreement',
        color: 'orange'
    },
    others: {
        title: 'Others',
        color: '#FFEDF5',
        textColor: '#FF529C'
    }
}

export const findIssueType = (type: string): Maintenance => {
    const issueType = issuesConstant[type as issueTypes]
    return issueType
}

export function handlePartialAmount(moveInDate: string, rentDay: number): boolean {
    // Get today's date
    const today = dayjs()

    // Get the 5th of next month
    const moveIn = dayjs(moveInDate)
    const fifthOfNextMonth = moveIn.add(1, 'month').date(rentDay)

    // Check if today is same as or after the 5th of next month
    return !today.isAfter(fifthOfNextMonth)
}

export const getIsAccessed = (
    roles: string[],
    user_modules: RoleSubmodule[],
    moduleName: string,
    submoduleName: string,
    accessType: string
): boolean => {
    // If the user is a super-admin, return true
    if (roles.includes('super-admin')) return true

    // Find the module by name
    const module = user_modules?.find((mod) => mod.name === moduleName)

    if (!module) {
        return false // Module not found
    }

    // Find the submodule by name
    const submodule = module?.sub_modules?.find((sub) => sub.name === submoduleName)

    if (!submodule) {
        return false // Submodule not found
    }

    // Return the access type (read/write) check
    return submodule?.access_types?.map((ac) => ac.name)?.includes(accessType) ?? false
}

export const generateOptions = (max: number, singular: string, plural: string): SelectProps['options'] =>
    Array.from({ length: max }, (_, i) => ({
        value: i + 1,
        label: `${i + 1} ${i === 0 ? singular : plural}`
    }))

export const formatPropertyString = (property: PropertiesListResult | Property): string => {
    return `${property?.name}${property?.area ? ` - ${property?.area}` : ''}${property?.pincode ? ` - ${property?.pincode}` : ''}`
}

export const isAdmin = (roles: { id: string; role: string }[]): boolean => {
    if (roles?.map((role) => role.role)?.includes('super-admin')) return true
    return roles?.some((role) => role.role === 'admin')
}

export const firstCapitalLetter = (str: string): string => {
    return (
        str
            ?.charAt(0) // Get the first character
            ?.toUpperCase() + // Capitalize it
        str
            ?.slice(1) // Get the rest of the string
            ?.replace(/[_-]/g, ' ')
    )
    // Replace underscores and hyphens with spaces
}

const keysToCompare: (keyof Signer)[] = ['email', 'name', 'phone']
export function isSameSignersByKeys(arr1: Signer[], arr2: Signer[]): boolean {
    if (arr1.length !== arr2.length) return false
    // Check that every object in arr1 matches its counterpart in arr2 based on specific keys
    return arr1.every((obj1, index) => {
        const obj2 = arr2[index]
        return keysToCompare.every((key) => obj1[key] === obj2[key])
    })
}
