import { resetPasswordType, userType } from 'types/user-management.types'
import { apiRequest } from 'utils/axios'
import { ACCESS_CONTROL_APIS, USER_MANAGEMENT_APIS } from 'utils/eps'

export const USERS_MANAGEMENT_GET = (
    page: number | undefined,
    limit: number | undefined,
    search: string,
    status: string
): Promise<unknown> => {
    return apiRequest.withToken({
        url: USER_MANAGEMENT_APIS.USERS_GET_POST,
        method: 'get',
        params: { page, limit, ...(search ? { search } : {}), ...(status ? { status } : {}) }
    })
}

export const USER_MANAGEMENT_ROLES_GET = (): Promise<unknown> => {
    return apiRequest.withToken({ url: USER_MANAGEMENT_APIS.USER_ROLES, method: 'get' })
}

export const USERS_MANAGEMENT_POST = (values: userType): Promise<unknown> => {
    return apiRequest.withToken({ url: USER_MANAGEMENT_APIS.USERS_GET_POST, method: 'post', data: values })
}

export const USERS_RESET_PASSWORD = (values: resetPasswordType): Promise<unknown> => {
    return apiRequest.withToken({ url: USER_MANAGEMENT_APIS.RESET_PASSOWRD, method: 'post', data: values })
}
export const USERS_MANAGEMENT_PATCH = (values: userType): Promise<unknown> => {
    const { id, ...dataToSend } = values
    return apiRequest.withToken({
        url: USER_MANAGEMENT_APIS.UPDATE_USER(id ?? ''),
        method: 'patch',
        data: dataToSend
    })
}

export const MASTER_PROPERTIES_GET = (
    page: number | undefined,
    limit: number | undefined,
    screen?: string,
    user_id?: string,
    property_manager_id?: string,
    maintainer_id?: string
): Promise<unknown> => {
    return apiRequest.withTokenProperty({
        url: USER_MANAGEMENT_APIS.MASTER_PROPERTIES,
        method: 'get',
        params: {
            page,
            limit,
            ...(screen ? { screen } : {}),
            ...(user_id ? { user_id } : {}),
            ...(property_manager_id ? { property_manager_id } : {}),
            ...(maintainer_id ? { maintainer_id } : {})
        }
    })
}

export const USER_PROFILE_GET = (cognitoId: string): Promise<unknown> => {
    return apiRequest.withToken({ url: USER_MANAGEMENT_APIS.USER_PROFILE(cognitoId), method: 'get' })
}

export const ROLEWISE_MODULES = (ids: string[]): Promise<unknown> => {
    return apiRequest.withToken({
        url: ACCESS_CONTROL_APIS.ROLE_MODULES,
        method: 'get',
        params: { role: ids.join(',') }
    })
}
