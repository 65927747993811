import { lazy } from 'react'
import { RouteTypes } from 'types/common.types'
import { RoleSubmodule } from 'types/roles.types'

//Auth Routes
const ChangePassword = lazy(() => import('components/auth/change-password'))
const ForgotPassword = lazy(() => import('components/auth/forgot-password'))
const SignIn = lazy(() => import('components/auth/sign-in'))

//Protected Routes
const Dashboard = lazy(() => import('components/dashboard'))
const ProfitLoss = lazy(() => import('components/profit-loss'))
const Blogs = lazy(() => import('components/blogs'))
const BlogCreation = lazy(() => import('components/blogs/blogs-create-update'))
const Leads = lazy(() => import('components/leads'))
const Onboarding = lazy(() => import('components/onboarding'))
const CreateOnboarding = lazy(() => import('components/onboarding/create-update-onboarding/index'))
const ViewOnboarding = lazy(() => import('components/onboarding/view-onboarding/index'))
const RaiseIssue = lazy(() => import('components/raise-issue'))
const UpdateIssue = lazy(() => import('components/raise-issue/update-close-issue/index'))
const SinglePropertyMangement = lazy(() => import('components/property-management/single-properties'))
const MasterPropertyManagement = lazy(() => import('components/property-management/master-properties'))

const UserMangement = lazy(() => import('components/user-management'))
const AddUser = lazy(() => import('components/user-management/user-form'))
const Home = lazy(() => import('components/website-content/home'))
const AboutUs = lazy(() => import('components/website-content/about'))
const Contact = lazy(() => import('components/website-content/contact'))
const ListProperty = lazy(() => import('components/website-content/list-property'))
const CompanyInformation = lazy(() => import('components/website-content/company-info'))
const Faq = lazy(() => import('components/website-content/faq'))
const CreateProperty = lazy(() => import('components/property-management/single-properties/create-update-property'))
const CreateMasterProperty = lazy(
    () => import('components/property-management/master-properties/create-update-master-property/index')
)
const ViewTenant = lazy(
    () =>
        import(
            'components/property-management/master-properties/create-update-master-property/tenant-details/view-tenant-details'
        )
)
const ViewProperty = lazy(() => import('components/property-management/common-components/view-property'))
const Testimonials = lazy(() => import('components/website-content/testimonials'))
const Address = lazy(() => import('components/website-content/address'))
const CreateAddress = lazy(() => import('components/website-content/address/create-address'))
const TermsConditions = lazy(() => import('components/website-content/terms-condition'))
const PrivacyPolicy = lazy(() => import('components/website-content/privacy-policy'))
const Roles = lazy(() => import('components/roles'))
const Amenities = lazy(() => import('components/amenities'))
const PropertyTags = lazy(() => import('components/property-tags'))
const ExpensesList = lazy(() => import('components/expenses-dues'))
const CreateExpense = lazy(() => import('components/expenses-dues/create-expense'))
const CreateDues = lazy(() => import('components/expenses-dues/create-dues'))
const AccessControl = lazy(() => import('components/roles/access-control'))
const ExpirationRequest = lazy(() => import('components/eviction/expiration-request'))
const RenewTenure = lazy(() => import('components/eviction/expiration-request/renew-depart-tenure'))
const DepartTenure = lazy(() => import('components/eviction/expiration-request/renew-depart-tenure'))
const Renewed = lazy(() => import('components/eviction/renewed'))
const Departed = lazy(() => import('components/eviction/departed'))
const EvictionCancelled = lazy(() => import('components/eviction/cancelled'))
const EvictionDeclined = lazy(() => import('components/eviction/declined'))
const SingleTenureDetails = lazy(() => import('components/eviction/common-comps/single-tenure-details'))
const EvictionCharges = lazy(
    () => import('components/eviction/expiration-request/renew-depart-tenure/full-&-final-(FnF)/add-charges')
)
const ExpenseCategory = lazy(() => import('components/expense-category'))

export const authRoutes: RouteTypes[] = [
    { path: '/forgot-password', name: 'Forgot password', routeKey: 'forgot-password', element: ForgotPassword },
    { path: '/sign-in', name: 'Sign in', routeKey: 'sign-in', element: SignIn }
]

export const noLayoutRoutes: RouteTypes[] = [
    { path: '/change-password', name: 'Change password', routeKey: 'change-password', element: ChangePassword }
]

export const newRoutes: {
    [key: string]: {
        name: string
        routes: RouteTypes[]
    }[]
} = {
    dashboard: [
        {
            name: 'dashboard',
            routes: [
                {
                    path: '/dashboard',
                    name: 'Dashboard',
                    routeKey: 'dashboard',
                    element: Dashboard,
                    access: ['read'],
                    type: 'dashboard'
                }
            ]
        }
    ],
    profit_loss: [
        {
            name: 'profit_loss',
            routes: [
                {
                    path: '/profit-loss',
                    name: 'Profit & Loss',
                    routeKey: 'profit-loss',
                    element: ProfitLoss,
                    access: ['read'],
                    type: 'profit_loss'
                }
            ]
        }
    ],
    website_content: [
        {
            name: 'home',
            routes: [
                {
                    path: '/home',
                    name: 'Home ',
                    routeKey: 'home',
                    element: Home,
                    access: ['read', 'update'],
                    type: 'home'
                }
            ]
        },
        {
            name: 'about',
            routes: [
                {
                    path: '/about',
                    name: 'About ',
                    routeKey: 'about',
                    element: AboutUs,
                    access: ['read', 'update'],
                    type: 'about'
                }
            ]
        },
        {
            name: 'contact',
            routes: [
                {
                    path: '/contact',
                    name: 'Contact ',
                    routeKey: 'contact',
                    element: Contact,
                    access: ['read', 'update'],
                    type: 'contact'
                }
            ]
        },
        {
            name: 'list_property',
            routes: [
                {
                    path: '/list-property',
                    name: 'List property ',
                    routeKey: 'list-property',
                    element: ListProperty,
                    access: ['read', 'update'],
                    type: 'list_property'
                }
            ]
        },
        {
            name: 'company_information',
            routes: [
                {
                    path: '/company-information',
                    name: 'Company information ',
                    routeKey: 'company-information',
                    element: CompanyInformation,
                    access: ['read', 'update'],
                    type: 'company_information'
                }
            ]
        },
        {
            name: 'faq',
            routes: [
                {
                    path: '/faq',
                    name: 'FAQ',
                    routeKey: 'faq',
                    element: Faq,
                    access: ['read', 'write', 'update', 'delete'],
                    type: 'faq'
                }
            ]
        },
        {
            name: 'testimonials',
            routes: [
                {
                    path: '/testimonials',
                    name: 'Testimonials',
                    routeKey: 'testimonials',
                    element: Testimonials,
                    access: ['read', 'write', 'update', 'delete'],
                    type: 'testimonials'
                }
            ]
        },
        {
            name: 'address',
            routes: [
                {
                    path: '/address',
                    name: 'Address',
                    routeKey: 'address',
                    element: Address,
                    access: ['read', 'delete'],
                    type: 'address'
                },
                {
                    path: '/address-create',
                    name: 'Address create',
                    routeKey: 'address-create',
                    element: CreateAddress,
                    access: ['write'],
                    type: 'address'
                },
                {
                    path: '/address-update/:addressUpdateId',
                    name: 'Address update',
                    routeKey: 'address-update',
                    element: CreateAddress,
                    access: ['update'],
                    type: 'address'
                }
            ]
        },
        {
            name: 'privacy_policy',
            routes: [
                {
                    path: '/privacy-policy',
                    name: 'Privacy policy',
                    routeKey: 'privacy-policy',
                    element: PrivacyPolicy,
                    access: ['read', 'update'],
                    type: 'privacy_policy'
                }
            ]
        },
        {
            name: 'terms_conditions',
            routes: [
                {
                    path: '/terms-conditions',
                    name: 'Terms and conditions',
                    routeKey: 'terms-conditions',
                    element: TermsConditions,
                    access: ['read', 'update'],
                    type: 'terms_conditions'
                }
            ]
        }
    ],
    property_management: [
        {
            name: 'master_property',
            routes: [
                {
                    path: '/master-property',
                    name: 'Master property',
                    routeKey: 'master-property',
                    element: MasterPropertyManagement,
                    access: ['read', 'write', 'update'],
                    type: 'master_property'
                },
                {
                    path: '/master-property/create-property',
                    name: 'Create master property',
                    routeKey: 'create-master-property',
                    element: CreateMasterProperty,
                    access: ['write'],
                    type: 'master_property'
                },
                {
                    path: '/master-property/update-property/:master_property_update_id',
                    name: 'Update master property',
                    routeKey: 'update-master-property',
                    element: CreateMasterProperty,
                    access: ['read', 'update'],
                    type: 'master_property'
                },
                {
                    path: '/master-property/update-property/view-tenant/:view_tenant_id',
                    name: 'View tenant',
                    routeKey: 'view-tenant',
                    element: ViewTenant,
                    access: ['read', 'update'],
                    type: 'master_property'
                }
            ]
        },
        {
            name: 'properties',
            routes: [
                {
                    path: '/single-property',
                    name: 'Single Property',
                    routeKey: 'single-property',
                    element: SinglePropertyMangement,
                    access: ['read', 'delete'],
                    type: 'properties'
                },
                {
                    path: '/property-management/create-property',
                    name: 'Create property',
                    routeKey: 'create-property',
                    element: CreateProperty,
                    access: ['write'],
                    type: 'properties'
                },
                {
                    path: '/property-management/view-property/:property_view_id',
                    name: 'View property',
                    routeKey: 'view-property',
                    element: ViewProperty,
                    access: ['read'],
                    type: 'properties'
                },
                {
                    path: '/property-management/create-property/:property_create_id',
                    name: 'Update property',
                    routeKey: 'update-property',
                    element: CreateProperty,
                    access: ['update'],
                    type: 'properties'
                },
                {
                    path: '/property-management/update-property/:property_update_id',
                    name: 'Update property',
                    routeKey: 'update-property',
                    element: CreateProperty,
                    access: ['update'],
                    type: 'properties'
                }
            ]
        }
    ],
    leads: [
        {
            name: 'tenant',
            routes: [
                {
                    path: '/leads/:leadType',
                    name: 'tenant',
                    routeKey: 'leads',
                    element: Leads,
                    access: ['read', 'update'],
                    type: 'tenant'
                }
            ]
        },
        {
            name: 'tour',
            routes: [
                {
                    path: '/leads/:leadType',
                    name: 'Tour',
                    routeKey: 'leads',
                    element: Leads,
                    access: ['read', 'update'],
                    type: 'tour'
                }
            ]
        },
        {
            name: 'owner',
            routes: [
                {
                    path: '/leads/:leadType',
                    name: 'Owner',
                    routeKey: 'leads',
                    element: Leads,
                    access: ['read', 'update'],
                    type: 'owner'
                }
            ]
        },
        {
            name: 'property_enquiry',
            routes: [
                {
                    path: '/leads/:leadType',
                    name: 'Property inquiry',
                    routeKey: 'leads',
                    element: Leads,
                    access: ['read', 'update'],
                    type: 'property_enquiry'
                }
            ]
        }
    ],
    master_directory: [
        {
            name: 'roles',
            routes: [
                {
                    path: '/master-directory/roles',
                    name: 'Roles',
                    routeKey: 'roles',
                    element: Roles,
                    access: ['read', 'delete'],
                    type: 'roles'
                },
                {
                    path: '/master-directory/roles/create-role',
                    name: 'Role create',
                    routeKey: 'create-role',
                    element: AccessControl,
                    access: ['write'],
                    type: 'roles'
                },
                {
                    path: '/master-directory/roles/update-role/:role_id',
                    name: 'Role update',
                    routeKey: 'update-role',
                    element: AccessControl,
                    access: ['update'],
                    type: 'roles'
                }
            ]
        },
        {
            name: 'amenities',
            routes: [
                {
                    path: '/master-directory/amenities',
                    name: 'Amenities',
                    routeKey: 'amenities',
                    element: Amenities,
                    access: ['read', 'write', 'update', 'delete'],
                    type: 'amenities'
                }
            ]
        },
        {
            name: 'property_tags',
            routes: [
                {
                    path: '/master-directory/property-tags',
                    name: 'Property tags',
                    routeKey: 'property-tags',
                    element: PropertyTags,
                    access: ['read', 'write', 'update', 'delete'],
                    type: 'property_tags'
                }
            ]
        },
        {
            name: 'expense_category',
            routes: [
                {
                    path: '/master-directory/expense-category',
                    name: 'Expense category',
                    routeKey: 'expense-category',
                    element: ExpenseCategory,
                    access: ['read', 'write', 'update', 'delete'],
                    type: 'expense_category'
                }
            ]
        }
    ],
    onboarding: [
        {
            name: 'onboarding',
            routes: [
                {
                    path: '/onboarding/:onboarding_type',
                    name: 'Onboarding',
                    routeKey: 'onboarding',
                    element: Onboarding,
                    access: ['read'],
                    type: 'onboarding'
                },
                {
                    path: '/onboarding/view-onboarding/:onboarding_view_id',
                    name: 'View onboarding',
                    routeKey: 'view-onboarding',
                    element: ViewOnboarding,
                    access: ['read'],
                    type: 'onboarding'
                },
                {
                    path: '/onboarding/update-onboarding/:onboarding_update_id',
                    name: 'Update onboarding',
                    routeKey: 'update-onboarding',
                    element: CreateOnboarding,
                    access: ['read', 'update'],
                    type: 'onboarding'
                }
            ]
        }
    ],
    expense_dues: [
        {
            name: 'expense_dues',
            routes: [
                {
                    path: '/expenses-dues',
                    name: 'Expenses Dues ',
                    routeKey: 'expenses-dues',
                    element: ExpensesList,
                    access: ['read'],
                    type: 'expense_dues'
                },
                {
                    path: '/expenses/create-expense',
                    name: 'Create expense',
                    routeKey: 'create-expense',
                    element: CreateExpense,
                    access: ['write'],
                    type: 'expense_dues'
                },
                {
                    path: '/expenses/update-expense/:update_expense_id',
                    name: 'Update expense',
                    routeKey: 'update-expense',
                    element: CreateExpense,
                    access: ['update'],
                    type: 'expense_dues'
                },
                {
                    path: '/dues/create-due',
                    name: 'Create due',
                    routeKey: 'create-due',
                    element: CreateDues,
                    access: ['write'],
                    type: 'expense_dues'
                },
                {
                    path: '/dues/update-due/:update_due_id',
                    name: 'Create due',
                    routeKey: 'create-due',
                    element: CreateDues,
                    access: ['update'],
                    type: 'expense_dues'
                }
            ]
        }
    ],
    issues: [
        {
            name: 'issues',
            routes: [
                {
                    path: '/raise-issue',
                    name: 'Raise issue',
                    routeKey: 'raise-issue',
                    element: RaiseIssue,
                    access: ['read'],
                    type: 'issues'
                },
                {
                    path: '/raise-issue/update-issue/:issue_update_id',
                    name: 'Update issue',
                    routeKey: 'update-issue',
                    element: UpdateIssue,
                    access: ['update', 'read'],
                    type: 'issues'
                }
            ]
        }
    ],
    user_management: [
        {
            name: 'users',
            routes: [
                {
                    path: '/user-management',
                    name: 'User management',
                    routeKey: 'user-management',
                    element: UserMangement,
                    access: ['read'],
                    type: 'users'
                },
                {
                    path: '/user-management/add-user',
                    name: 'Add user',
                    routeKey: 'add-user',
                    element: AddUser,
                    access: ['write'],
                    type: 'users'
                },
                {
                    path: '/user-management/update-user/:user_update_id',
                    name: 'Update user',
                    routeKey: 'update-user',
                    element: AddUser,
                    access: ['update'],
                    type: 'users'
                }
            ]
        }
    ],
    blogs: [
        {
            name: 'blogs',
            routes: [
                {
                    path: '/blogs',
                    name: 'Blogs',
                    routeKey: 'blogs',
                    element: Blogs,
                    access: ['read', 'delete'],
                    type: 'blogs'
                },
                {
                    path: '/blogs-create',
                    name: 'Blogs',
                    routeKey: 'blogs-create',
                    element: BlogCreation,
                    access: ['write'],
                    type: 'blogs'
                },
                {
                    path: '/blog-update/:blogUpdateId',
                    name: 'Blogs',
                    routeKey: 'blogs-update',
                    element: BlogCreation,
                    access: ['update'],
                    type: 'blogs'
                }
            ]
        }
    ],
    eviction: [
        {
            name: 'expiration_request',
            routes: [
                {
                    path: '/eviction/expiration-request',
                    name: 'Expiration Request',
                    routeKey: 'expiration-request',
                    element: ExpirationRequest,
                    access: ['read', 'update'],
                    type: 'expiration_request'
                },
                {
                    path: '/eviction/expiration-request/renew-tenure/:renew_tenure_id',
                    name: 'Renew tenure',
                    routeKey: 'renew-tenure',
                    element: RenewTenure,
                    access: ['update'],
                    type: 'expiration_request'
                },
                {
                    path: '/eviction/expiration-request/depart-request/depart-tenure/:depart_tenure_id',
                    name: 'Depart tenure',
                    routeKey: 'depart-tenure',
                    element: DepartTenure,
                    access: ['update'],
                    type: 'expiration_request'
                },
                {
                    path: '/eviction/expiration-request/tenure-expiry/depart-tenure/:tenure_expiry_depart_tenure_id',
                    name: 'Depart tenure',
                    routeKey: 'depart-tenure',
                    element: DepartTenure,
                    access: ['update'],
                    type: 'expiration_request'
                },
                {
                    path: '/eviction/expiration-request/depart-tenure/add-charges',
                    name: 'Add charges',
                    routeKey: 'add-charges',
                    element: EvictionCharges,
                    access: ['update'],
                    type: 'expiration_request'
                },
                {
                    path: '/eviction/expiration-request/depart-tenure/update-charges/:update_charge_id',
                    name: 'Update charges',
                    routeKey: 'update-charges',
                    element: EvictionCharges,
                    access: ['update'],
                    type: 'expiration_request'
                }
            ]
        },
        {
            name: 'renewed',
            routes: [
                {
                    path: '/eviction/renewed/:view_renew_tenure_id',
                    name: 'Renewd',
                    routeKey: 'renewed',
                    element: SingleTenureDetails,
                    access: ['read'],
                    type: 'renewed'
                },
                {
                    path: '/eviction/renewed',
                    name: 'Renewd',
                    routeKey: 'renewed',
                    element: Renewed,
                    access: ['read'],
                    type: 'renewed'
                }
            ]
        },
        {
            name: 'departed',
            routes: [
                {
                    path: '/eviction/departed/:view_depart_tenure_id',
                    name: 'Departed',
                    routeKey: 'departed',
                    element: SingleTenureDetails,
                    access: ['read'],
                    type: 'departed'
                },
                {
                    path: '/eviction/departed',
                    name: 'Departed',
                    routeKey: 'departed',
                    element: Departed,
                    access: ['read'],
                    type: 'departed'
                },
                {
                    path: '/eviction/departed/add-charges',
                    name: 'Add charges',
                    routeKey: 'add-charges',
                    element: EvictionCharges,
                    access: ['update'],
                    type: 'departed'
                },
                {
                    path: '/eviction/departed/update-charges/:departed_update_charge_id',
                    name: 'Update charges',
                    routeKey: 'update-charges',
                    element: EvictionCharges,
                    access: ['update'],
                    type: 'departed'
                }
            ]
        },
        {
            name: 'cancelled',
            routes: [
                {
                    path: '/eviction/cancelled/:view_cancel_tenure_id',
                    name: 'Cancelled',
                    routeKey: 'cancelled',
                    element: SingleTenureDetails,
                    access: ['read'],
                    type: 'cancelled'
                },
                {
                    path: '/eviction/cancelled',
                    name: 'Cancelled',
                    routeKey: 'cancelled',
                    element: EvictionCancelled,
                    access: ['read'],
                    type: 'cancelled'
                }
            ]
        },
        {
            name: 'declined',
            routes: [
                {
                    path: '/eviction/declined/:view_declined_tenure_id',
                    name: 'Declined',
                    routeKey: 'declined',
                    element: SingleTenureDetails,
                    access: ['read'],
                    type: 'declined'
                },
                {
                    path: '/eviction/declined',
                    name: 'Declined',
                    routeKey: 'declined',
                    element: EvictionDeclined,
                    access: ['read'],
                    type: 'declined'
                }
            ]
        }
    ]
}

export const getUserAccessRoutes = (moduleName: string, accessed: RoleSubmodule[]): Map<string, string[]> => {
    const module = accessed?.find((m) => m.name === moduleName)
    if (!module) return new Map()

    const accessMap = new Map()
    module?.sub_modules?.forEach((subModule) => {
        accessMap.set(
            subModule.name,
            subModule.access_types.map((at) => at.name)
        )
    })

    return accessMap
}

export const renderRoutes = (accessed: RoleSubmodule[], roles: string[]): RouteTypes[] => {
    const isSuperAdmin = roles.includes('super-admin')
    if (isSuperAdmin) {
        // If the user is a super-admin, return all routes without filtering
        return Object.keys(newRoutes).flatMap((category) => newRoutes[category].flatMap((module) => module.routes))
    }
    return Object.keys(newRoutes).reduce((acc, category) => {
        const userAccessMap = getUserAccessRoutes(category, accessed)

        newRoutes[category]?.forEach((module) => {
            module?.routes?.forEach((route) => {
                const userAccess = userAccessMap.get(route.type ?? '')
                if (
                    userAccess &&
                    route?.access &&
                    route?.access?.some((accessType) => userAccess.includes(accessType))
                ) {
                    acc.push(route)
                }
            })
        })

        return acc
    }, [] as RouteTypes[])
}
