import { useContext } from 'react'
import { Image, message, Modal } from 'antd'

import { CommonStateContext } from '../common-states/common-states'
import { imageViewer } from 'utils/constant'

export default function ImagePreviewModal(): JSX.Element {
    const { previewOpen, setPreviewOpen, previewTitle, setPreviewTitle, previewImage, setPreviewImage } =
        useContext(CommonStateContext)

    const handleCancel = (): void => {
        setPreviewOpen(false)
        setPreviewTitle('')
        setPreviewImage('')
    }

    return (
        <Modal centered open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
            {previewTitle.split('|').pop() === 'BASE64' ? (
                <Image
                    preview={false}
                    width={'100%'}
                    style={{ maxWidth: '100%', width: '100%' }}
                    srcSet={previewImage}
                    fallback={previewImage}
                    onError={(): void => {
                        message.error('Image not found')
                    }}
                    alt="example"
                />
            ) : (
                <picture style={{ width: '100%' }}>
                    <source media="screen and (min-width: 1080px)" srcSet={imageViewer(previewImage, '1080') + '1'} />
                    <source media="screen and (min-width: 750px)" srcSet={imageViewer(previewImage, '750')} />
                    <source media="screen and (min-width: 640px)" srcSet={imageViewer(previewImage, '640')} />
                    <source media="screen and (min-width: 320px)" srcSet={imageViewer(previewImage, '320')} />
                    <Image
                        preview={false}
                        width={'100%'}
                        style={{ maxWidth: '100%', width: '100%' }}
                        srcSet={imageViewer(previewImage, 'raw')}
                        fallback={imageViewer(previewImage, 'raw')}
                        onError={(): void => {
                            message.error('Image not found')
                        }}
                        alt="example"
                    />
                </picture>
            )}
        </Modal>
    )
}
