/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { message } from 'antd'
import { getAccessToken } from 'utils/aws-get-token'

const baseURL = process.env.REACT_APP_API_URL
const baseURLAuth = process.env.REACT_APP_API_URL_WITH_AUTH
const baseURLBooking = process.env.REACT_APP_API_BOOKING_URL
const baseURLPayment = process.env.REACT_APP_API_PAYMENT_URL
const baseURLTenant = process.env.REACT_APP_API_TENANT_URL
const baseURLProperty = process.env.REACT_APP_API_PROPERTY_URL

interface APIOptions extends AxiosRequestConfig {
    url: string | undefined
    method: string | undefined
    data?: unknown
}
export const getAccessTokenFromAWS = async (): Promise<string | undefined> => {
    const id: any = await getAccessToken()
    return id?.idToken?.toString()
}

const onSuccess = (response: AxiosResponse): any => {
    return response.data
}
const onError = (error: AxiosError | any): any => {
    if (error?.response?.status == 401) {
        message.error('Session token is expired')
    } else if (error.code === 'ERR_NETWORK') {
        message.error('Network connection error')
    } else if (error.response.status == 403) {
        throw error?.response?.data?.data ? error?.response?.data?.data : error?.response?.data?.error
    } else if (error.response.status == 400) {
        throw error?.response?.data?.data ? error?.response?.data?.data : error?.response?.data?.error
    } else if (error?.response?.status == 500) {
        throw error?.response?.data?.data ? error?.response?.data?.data : error?.response?.data?.error
    } else {
        let errorMessage: any = ''
        if (typeof error?.response?.data?.error == 'string') {
            errorMessage.message = JSON.parse(error?.response?.data?.error)
        } else {
            errorMessage = error?.response?.data?.error
        }
        throw errorMessage ? errorMessage : error
    }
}
export class apiRequest {
    public static async withToken({ ...options }: APIOptions): Promise<any> {
        const token = await getAccessTokenFromAWS()
        const client = axios.create({ baseURL: baseURLAuth })
        client.defaults.headers.common = {
            'Content-Type': 'application/json',
            Authorization: `${token}`
        }
        return client(options).then(onSuccess).catch(onError)
    }
    public static async withoutToken({ ...options }: APIOptions): Promise<any> {
        const client = axios.create({ baseURL: baseURL })
        client.defaults.headers.common = {
            'Content-Type': 'application/json'
        }
        return client(options).then(onSuccess).catch(onError)
    }

    public static async withTokenBooking({ ...options }: APIOptions): Promise<any> {
        const token = await getAccessTokenFromAWS()
        const client = axios.create({ baseURL: baseURLBooking })
        client.defaults.headers.common = {
            'Content-Type': 'application/json',
            Authorization: `${token}`
        }
        return client(options).then(onSuccess).catch(onError)
    }
    public static async withTokenPayment({ ...options }: APIOptions): Promise<any> {
        const token = await getAccessTokenFromAWS()
        const client = axios.create({ baseURL: baseURLPayment })
        client.defaults.headers.common = {
            'Content-Type': 'application/json',
            Authorization: `${token}`
        }
        return client(options).then(onSuccess).catch(onError)
    }
    public static async withTenantTokenBooking({ ...options }: APIOptions): Promise<any> {
        const token = await getAccessTokenFromAWS()
        const client = axios.create({ baseURL: baseURLTenant })
        client.defaults.headers.common = {
            'Content-Type': 'application/json',
            Authorization: `${token}`
        }
        return client(options).then(onSuccess).catch(onError)
    }

    public static async withTokenTenant({ ...options }: APIOptions): Promise<any> {
        const token = await getAccessTokenFromAWS()
        const client = axios.create({ baseURL: baseURLTenant })
        client.defaults.headers.common = {
            'Content-Type': 'application/json',
            Authorization: `${token}`
        }
        return client(options).then(onSuccess).catch(onError)
    }
    public static async withTokenProperty({ ...options }: APIOptions): Promise<any> {
        const token = await getAccessTokenFromAWS()
        const client = axios.create({ baseURL: baseURLProperty })
        client.defaults.headers.common = {
            'Content-Type': 'application/json',
            Authorization: `${token}`
        }
        return client(options).then(onSuccess).catch(onError)
    }
}
