import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { SIDEBAR_COUNT_GET } from './api-helper'
import { SidebarCountRoot } from 'types/common.types'

export const USE_GET_SIDEBAR_COUNTS = (): UseQueryResult<SidebarCountRoot, Error> =>
    useQuery({
        queryKey: ['USE_GET_SIDEBAR_COUNTS'],
        queryFn: () => SIDEBAR_COUNT_GET(),
        retry: 3
    })
