import { App, ConfigProvider, theme } from 'antd'
import { ReactNode, useRef } from 'react'

type Props = { children: ReactNode }

export default function AntdConfig({ children }: Props): JSX.Element {
    const modalContainerRef = useRef<HTMLDivElement>(null)

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#ED1C24',
                    colorTextSecondary: '#A7A9AC',
                    fontFamily: 'Axiforma',
                    colorError: 'red'
                },
                algorithm: theme.defaultAlgorithm,

                components: {
                    Table: {
                        fontSize: 14,
                        padding: 20,
                        headerBg: '#ffffff',
                        colorBgContainer: '#fcfcfc',
                        borderColor: '#d9d9d9c2',
                        borderRadius: 10,
                        rowHoverBg: '#ffd8da5c'
                    },
                    Pagination: {
                        controlHeight: 40,
                        colorBgBase: '#ffffff',
                        controlHeightSM: 30
                    },
                    Input: {
                        controlHeight: 40,
                        borderRadius: 10,
                        activeBorderColor: '#FFECED',
                        controlHeightSM: 34,
                        borderRadiusSM: 8
                    },
                    InputNumber: {
                        controlHeight: 40,
                        borderRadius: 10,
                        controlHeightSM: 34,
                        borderRadiusSM: 8
                    },
                    Select: {
                        controlHeight: 40,
                        borderRadius: 10,
                        controlHeightSM: 34,
                        borderRadiusSM: 8
                    },
                    DatePicker: {
                        controlHeight: 40,
                        borderRadius: 10,
                        controlHeightSM: 34,
                        borderRadiusSM: 8
                    },
                    Switch: {
                        controlHeight: 40,
                        controlHeightSM: 34,
                        borderRadiusSM: 8
                    },
                    Checkbox: {
                        controlHeight: 40,
                        controlHeightSM: 34,
                        borderRadiusSM: 8
                    },
                    Radio: {
                        controlHeight: 40,
                        controlHeightSM: 34,
                        borderRadiusSM: 8
                    },
                    Button: {
                        controlHeight: 40,
                        fontFamily: 'Axiforma-SemiBold',
                        controlHeightLG: 50,
                        borderRadiusLG: 10,
                        paddingBlockLG: '13px 37px',
                        colorTextDisabled: 'gray',
                        colorBgContainerDisabled: '#00000029',
                        borderColorDisabled: 'none',
                        borderRadius: 10,
                        borderRadiusSM: 8,
                        fontSize: 14,
                        fontSizeLG: 16,
                        fontSizeSM: 12
                    },
                    Menu: {
                        fontFamily: 'Axiforma-SemiBold',
                        darkSubMenuItemBg: 'transparent',
                        darkItemHoverBg: '#ED1C24',
                        darkItemHoverColor: '#FFFFFF'
                    },
                    Segmented: {
                        fontWeightStrong: 600,
                        itemSelectedBg: '#ED1C24',
                        itemSelectedColor: '#FFFFFF',
                        trackPadding: 4,
                        itemActiveBg: '#ED1C24',
                        itemHoverColor: '#FFFFFF'
                    }
                }
            }}
            getPopupContainer={(): HTMLElement => modalContainerRef.current as HTMLDivElement}>
            <div ref={modalContainerRef}>
                <App>{children}</App>
            </div>
        </ConfigProvider>
    )
}
