/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Suspense, lazy, useState } from 'react'
import { Amplify } from 'aws-amplify'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import { awsConfig } from 'utils/aws-config'
import AntConfig from 'components/common-components/antd-config'
import CommonStates from 'components/common-components/common-states/common-states'
import Spinner from 'components/common-components/spinner'
import Logout from 'components/auth/logout'
import ImagePreviewModal from 'components/common-components/common-img-preview'

const AuthRoutes = lazy(() => import('utils/components/auth-routes'))
const ProtectedRoutes = lazy(() => import('utils/components/protected-routes'))
const RequireAuth = lazy(() => import('utils/components/require-auth'))
const NoAuth = lazy(() => import('utils/components/no-auth'))
import 'antd/dist/reset.css'
import 'material-symbols/outlined.css'
import './App.scss'
import { RouteTypes } from 'types/common.types'
import { userType } from 'types/user-management.types'
import { RoleSubmodule } from 'types/roles.types'

Amplify.configure({
    Auth: {
        Cognito: {
            userPoolId: awsConfig.user_pool_id ? awsConfig.user_pool_id : '',
            userPoolClientId: awsConfig.user_client_id ? awsConfig.user_client_id : ''
        }
    }
})

export const MainContext = React.createContext<{
    rolesArray: RoleSubmodule[]
    setRolesArray: (rolesArray: RoleSubmodule[]) => void
    myRoutes: RouteTypes[] | null
    setRoutes: (myRoutes: RouteTypes[] | null) => void
    profile:
        | {
              noAccess: boolean | undefined
              id: undefined
              is_property_manager?: boolean
              is_maintainer?: boolean
              roles?: { id: string; role: string }[]
          }
        | userType
        | undefined
    setProfile: (
        profile:
            | userType
            | { noAccess: boolean | undefined; id: undefined; is_property_manager?: boolean; is_maintainer?: boolean }
            | undefined
    ) => void
}>({
    rolesArray: [],
    setRolesArray: (rolesArray: RoleSubmodule[]) => rolesArray,
    myRoutes: [],
    setRoutes: (myRoutes: RouteTypes[] | null) => myRoutes,
    profile: undefined,
    setProfile: (
        profile:
            | {
                  noAccess: boolean | undefined
                  id: undefined
                  is_property_manager?: boolean
                  is_maintainer?: boolean
                  roles?: { id: string; role: string }[]
              }
            | userType
            | undefined
    ) => profile
})
function App(): JSX.Element {
    const [rolesArray, setRolesArray] = useState<RoleSubmodule[]>([])
    const [myRoutes, setRoutes] = useState<RouteTypes[] | null>([])
    const [profile, setProfile] = useState<
        | {
              noAccess: boolean | undefined
              id: undefined
              is_property_manager?: boolean
              is_maintainer?: boolean
              roles?: []
          }
        | userType
        | undefined
    >(undefined)

    return (
        <AntConfig>
            <MainContext.Provider value={{ rolesArray, setRolesArray, myRoutes, setRoutes, profile, setProfile }}>
                <Router>
                    <Routes>
                        <Route
                            path="/auth/*"
                            element={
                                <Suspense fallback={<Spinner />}>
                                    <NoAuth>
                                        <Suspense fallback={<Spinner />}>
                                            <AuthRoutes />
                                        </Suspense>
                                    </NoAuth>
                                </Suspense>
                            }
                        />
                        <Route
                            path="/*"
                            element={
                                <Suspense fallback={<Spinner />}>
                                    <RequireAuth>
                                        <Suspense fallback={<Spinner />}>
                                            <CommonStates>
                                                <ProtectedRoutes />
                                                <Logout />
                                                <ImagePreviewModal />
                                            </CommonStates>
                                        </Suspense>
                                    </RequireAuth>
                                </Suspense>
                            }
                        />
                    </Routes>
                </Router>
            </MainContext.Provider>
        </AntConfig>
    )
}

export default App
